@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body {
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
}
body * {
  outline: none;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
}

.fontEn {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}

a {
  color: #000000;
  text-decoration: none;
}

ul, ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

* {
  box-sizing: border-box;
}

sup, sub {
  font-size: 0.6em;
}

.dInlineBlock {
  display: inline-block;
}

* + .btnWrap {
  margin-top: 3em;
}

.btnWrap {
  text-align: center;
}

.postContent .btnWrap a,
.commonBtn {
  text-align: center;
  vertical-align: middle;
  text-decoration: none !important;
  transition: 0.2s color, 0.2s background;
  position: relative;
}
.postContent .btnWrap a > div,
.commonBtn > div {
  position: relative;
}
.postContent .btnWrap a > [class^=icon],
.commonBtn > [class^=icon] {
  margin-right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.postContent .btnWrap a.btnL > div,
.commonBtn.btnL > div {
  position: relative;
}
.postContent .btnWrap a.btnS > div,
.commonBtn.btnS > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.postContent .btnWrap a.btnM > div,
.commonBtn.btnM > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.postContent .btnWrap a.btnPink,
.commonBtn.btnPink {
  color: #fff;
  background: #c90056;
}
.postContent .btnWrap a.btnPink:before,
.commonBtn.btnPink:before {
  background: #ab002e;
}
.postContent .btnWrap a.btnNavy,
.commonBtn.btnNavy {
  color: #fff;
  background: #34405b;
}
.postContent .btnWrap a.btnNavy:before,
.commonBtn.btnNavy:before {
  background: #13192a;
}
.postContent .btnWrap a.btnBorderPink,
.commonBtn.btnBorderPink {
  color: #c90056;
  border: 1px solid #c90056;
}
.postContent .btnWrap a.btnBorderNavy,
.commonBtn.btnBorderNavy {
  color: #34405b;
  border: 1px solid #34405b;
}
.postContent .btnWrap a.btnBlock,
.commonBtn.btnBlock {
  display: block;
  width: 100%;
}
.postContent .btnWrap a [class^=icon],
.commonBtn [class^=icon] {
  vertical-align: middle;
}
.postContent .btnWrap a.btnBack,
.commonBtn.btnBack {
  color: #666666;
  background: #e2e2e2;
}
.postContent .btnWrap a.btnBack [class^=icon],
.commonBtn.btnBack [class^=icon] {
  transform: scale(-1, 1);
}
.postContent .btnWrap a.iconFixedDash div:before,
.commonBtn.iconFixedDash div:before {
  content: "";
  display: block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.postContent .btnWrap a .iconPdf,
.postContent .btnWrap a .iconWord,
.postContent .btnWrap a .iconExcel,
.commonBtn .iconPdf,
.commonBtn .iconWord,
.commonBtn .iconExcel {
  vertical-align: text-bottom;
}
.postContent .btnWrap a [class^=iconFixed],
.commonBtn [class^=iconFixed] {
  position: absolute;
}

.iconTel {
  display: inline-block;
  background: url(../images/common/icon_tel.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.iconMail {
  display: inline-block;
  background: url(../images/common/icon_mail.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.iconDashWhite {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
}

.iconDashWhiteTop {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
  transform: rotate(-90deg) !important;
}

.iconDashBlack {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_black.svg) no-repeat;
  background-size: contain;
}

.iconDashGray {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_gray.svg) no-repeat;
  background-size: contain;
}

.iconDashPink {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_pink.svg) no-repeat;
  background-size: contain;
}

.iconDownload {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_download_white.svg) no-repeat center;
  background-size: contain;
}

.iconCad {
  content: "";
  display: inline-block;
  background: url(../images/common/icon_cad.svg) no-repeat center;
  background-size: contain;
}

.iconBlank,
.iconBlankBlack,
.iconBlankPink {
  content: "";
  display: inline-block;
}

.iconBlank {
  background: url(../images/common/icon_blank_white.svg) no-repeat center;
  background-size: 17px;
}

.iconBlankBlack {
  background: url(../images/common/icon_blank_black.svg) no-repeat center;
  background-size: 17px;
}

.iconBlankPink {
  background: url(../images/common/icon_blank_pink.svg) no-repeat center;
  background-size: 17px;
}

.iconPdf,
.iconPdfWhite {
  content: "";
  display: inline-block;
  background-size: 17px;
}

.iconPdf {
  background: url(../images/common/icon_pdf.svg) no-repeat center;
}

.iconPdfWhite {
  background: url(../images/common/icon_pdf_white.svg) no-repeat center;
}

.iconWord,
.iconWordWhite {
  content: "";
  display: inline-block;
  background-size: 17px;
}

.iconWord {
  background: url(../images/common/icon_word.svg) no-repeat center;
}

.iconWordWhite {
  background: url(../images/common/icon_word_white.svg) no-repeat center;
}

.iconExcel,
.iconExcelWhite {
  content: "";
  display: inline-block;
  background-size: 17px;
}

.iconExcel {
  background: url(../images/common/icon_excel.svg) no-repeat center;
}

.iconExcelWhite {
  background: url(../images/common/icon_excel_white.svg) no-repeat center;
}

@keyframes isUsual {
  0% {
    position: fixed;
    top: 0;
  }
  100% {
    position: absolute;
    top: 0;
  }
}
@keyframes isFixed {
  0% {
    top: -120px;
  }
  100% {
    top: 0;
  }
}
#globalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transition: 0.2s all;
}
#globalHeader.isUsual {
  animation: isUsual 0.2s both;
}
#globalHeader.isFixed {
  position: fixed;
  border-bottom: 1px solid #b8b8b8;
  animation: isFixed 0.2s both;
}
#globalHeader .ghLogo a {
  display: block;
}
#globalHeader .ghLogo a img {
  image-rendering: -webkit-optimize-contrast;
}
#globalHeader .ghMenu .ghNavList > li > p > a {
  display: block;
}
#globalHeader .ghMenu .ghList > li.ghListContact a {
  background: #c90056;
}
#globalHeader .ghMenu .ghList > li.ghListDocument a {
  background: #4c4398;
}
#globalHeader .ghMenu .ghList > li.ghListRecruit a {
  background: #0062ac;
}
#globalHeader .ghMenu .ghList > li > a .iconContact {
  background: url(../images/common/icon_mail.svg) no-repeat center;
  background-size: contain;
}
#globalHeader .ghMenu .ghList > li > a .iconDocument {
  background: url(../images/common/icon_document.svg) no-repeat center;
  background-size: contain;
}
#globalHeader .ghMenu .ghLanguage {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}
#globalHeader .ghMenu .ghLanguage > li:after {
  content: "";
  width: 1px;
  background: #cecece;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearchBtn {
  display: block;
  background: url(../images/common/icon_search_black.svg) no-repeat;
  background-size: contain;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch #___gcse_0 {
  background: #fff;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch form.gsc-search-box {
  margin-bottom: 0 !important;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch table.gsc-search-box {
  margin-bottom: 0 !important;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gsib_a {
  padding: 0 !important;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch table.gsc-search-box td.gsc-input {
  padding: 0 !important;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input-box {
  border: none;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gsst_a {
  display: flex;
  align-items: center;
  justify-content: center;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gscb_a {
  color: #34405b !important;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-search-button-v2 {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: url(../images/common/icon_search_black.svg) no-repeat center;
  background-size: 20px 21px;
}
#globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-search-button-v2 svg {
  display: none;
}

#footContact .inner .footContactNotes {
  text-align: center;
}

.sectionHeader .sectionTitle {
  text-align: center;
  font-weight: 500;
}
.sectionHeader .sectionTitleSub {
  margin-top: 0;
  text-align: center;
  color: #c90056;
  line-height: 1;
  font-weight: bold;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  letter-spacing: 0.06em;
}

.pickupListItem .pickupItem {
  background: #fff;
}
.pickupListItem .pickupItem > a {
  overflow: hidden;
  position: relative;
}
.pickupListItem .pickupItem > a .pickupItemText .pickupItemTag {
  display: inline-block;
  color: #c90056;
  line-height: 1;
  background: #fff;
  position: relative;
}
.pickupListItem .pickupItem > a .pickupItemText .pickupItemTag:before {
  content: "";
  height: 1px;
  background: #dadada;
  position: absolute;
  left: 0;
}
.pickupListItem .pickupItem > a .pickupItemText .pickupItemNote {
  background: #fff;
}
#globalFooter {
  position: relative;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
#globalFooter .gfSitemap .gfAddress .gfLogoList > li > a {
  display: block;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
#globalFooter .gfSub {
  width: 100%;
}
@media all and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
#globalFooter .gfSub .gfNav > li {
  position: relative;
}
#globalFooter #pageTop {
  background: #34405b;
  position: absolute;
}
#globalFooter #pageTop:before {
  content: "";
  display: block;
  background: url(../images/common/icon_dash_white.svg) no-repeat center;
  background-size: contain;
  transform: rotate(-90deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.cookieConsentLayout {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 99;
  transition: 0.4s 1s transform ease-in-out;
  transform: translateY(0);
  backdrop-filter: blur(8px);
  z-index: 102;
}
.cookieConsentLayout:not(.jsCookieConsent) {
  transform: translateY(calc(100% + 50px));
}
.cookieConsentLayout .cookieText {
  font-weight: bold;
}
.cookieConsentLayout .cookieText a {
  color: #34405b;
  text-decoration: underline;
}
.cookieConsentLayout .cookieBtn {
  appearance: none;
  border: none;
  text-align: center;
  background: transparent;
}
.cookieConsentLayout .cookieBtn > a {
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0;
  color: #fff;
  border-radius: 9999px;
  background: #34405b;
  position: relative;
}
.cookieConsentLayout .cookieClose {
  appearance: none;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  transition: all 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.cookieConsentLayout .cookieClose > span {
  display: block;
  width: 25px;
  height: 2px;
  background: #34405b;
  position: absolute;
  right: 0;
  left: 0;
}
.cookieConsentLayout .cookieClose > span:nth-child(1) {
  transform: rotate(45deg);
}
.cookieConsentLayout .cookieClose > span:nth-child(2) {
  transform: rotate(-45deg);
}

/*
:target {
 @if $type == sp{
  animation: anchor_sp  0.1s;
 }
	@if $type == pcHeaderTb{
  animation: anchor_tb  0.1s;
 }
	@if $type == pcHeader{
  background: #000;
  animation: anchor_pc  0.1s;
 }
}

@keyframes  anchor_sp{
 0% { padding-top: 120px; margin-top: -120px; }
 100% { padding-top: 0; margin-top: 0; }
}

@keyframes  anchor_tb{
 0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
 100% { padding-top: 0; margin-top: 0; }
}

@keyframes  anchor_pc{
 0% { padding-top: 226px; margin-top: -226px; }
 100% { padding-top: 0; margin-top: 0; }
}
*/
/* ココから下には記入禁止 */
@media screen and (max-width: 980px) {
  body {
    line-height: 1.5;
    font-size: 14px;
  }
  body.isOpen {
    position: relative;
    overflow: hidden;
  }
  body.isOpen:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #34405b;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0.8;
    overflow: hidden;
  }
  .tel {
    color: #005ba4;
    text-decoration: underline;
  }
  .dInlineBlock {
    white-space: nowrap;
  }
  .btnWrap {
    line-height: 1.5 !important;
  }
  .postContent .btnWrap a,
  .commonBtn {
    display: block;
  }
  .postContent .btnWrap a > [class^=icon],
  .commonBtn > [class^=icon] {
    right: 11px;
  }
  .postContent .btnWrap a + .commonBtn,
  .commonBtn + .commonBtn {
    margin-top: 15px;
  }
  .postContent .btnWrap a:not(.btnBlock),
  .commonBtn:not(.btnBlock) {
    display: block;
  }
  .postContent .btnWrap a.btnL > div,
  .commonBtn.btnL > div {
    padding: 15px 8px 15px;
    font-size: 14px;
  }
  .postContent .btnWrap a.btnS > div,
  .commonBtn.btnS > div {
    font-size: 14px;
  }
  .postContent .btnWrap a.btnM > div,
  .commonBtn.btnM > div {
    padding: 15px 8px 15px;
    font-size: 14px;
  }
  .postContent .btnWrap a.btnS > div,
  .commonBtn.btnS > div {
    padding: 7px 8px 8px;
    min-width: inherit;
  }
  .postContent .btnWrap a.btnBack,
  .commonBtn.btnBack {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .postContent .btnWrap a.iconFixedDash div,
  .commonBtn.iconFixedDash div {
    padding: 18px 40px;
  }
  .postContent .btnWrap a.iconFixedDash div:before,
  .commonBtn.iconFixedDash div:before {
    width: 10px;
    height: 11px;
    left: 25px;
  }
  .postContent .btnWrap a [class^=iconFixed],
  .commonBtn [class^=iconFixed] {
    width: 10px;
    height: 11px;
    left: 25px;
  }
  .btnBackWrap {
    margin-top: 20px;
  }
  .iconTel {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
  .iconMail {
    width: 19px;
    height: 12px;
    margin-right: 6px;
  }
  .iconDashWhite {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashWhiteTop {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashBlack {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashGray {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashPink {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDownload {
    width: 18px;
    height: 16px;
    margin-right: 12px;
  }
  .iconCad {
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }
  .iconBlank,
  .iconBlankBlack,
  .iconBlankPink {
    width: 17px;
    height: 17px;
    margin-right: 12px;
  }
  .iconPdf,
  .iconPdfWhite {
    width: 17px;
    height: 22px;
    margin-right: 12px;
  }
  .iconWord,
  .iconWordWhite {
    width: 17px;
    height: 22px;
    margin-right: 12px;
  }
  .iconExcel,
  .iconExcelWhite {
    width: 17px;
    height: 22px;
  }
  main {
    padding-top: 60px;
  }
  @keyframes isUsual {
    0% {
      position: fixed;
      top: 0;
    }
    100% {
      position: absolute;
      top: 0;
    }
  }
  @keyframes isFixed {
    0% {
      top: -120px;
    }
    100% {
      top: 0;
    }
  }
  #globalHeader {
    height: 60px;
    padding-left: 20px;
  }
  #globalHeader .ghLogo {
    width: 50%;
    max-width: 180px;
    margin-right: auto;
  }
  #globalHeader .ghLogo a img {
    transform: translateY(-2px);
  }
  #globalHeader #menuBtn {
    display: block;
    width: 60px;
    height: 60px;
    background: #34405b;
    position: relative;
    flex: none;
  }
  #globalHeader #menuBtn > span {
    display: block;
    height: 2px;
    border-radius: 6px;
    background: #fff;
    position: absolute;
    transition: 0.2s opacity, 0.2s transform;
  }
  #globalHeader #menuBtn > span:nth-child(1) {
    width: 24px;
    top: 22px;
    right: 18px;
  }
  #globalHeader #menuBtn > span:nth-child(2) {
    width: 18px;
    top: 0;
    bottom: 0;
    right: 18px;
    margin: auto;
  }
  #globalHeader #menuBtn > span:nth-child(3) {
    width: 12px;
    bottom: 22px;
    right: 18px;
  }
  #globalHeader #menuBtn.isOpen span:nth-child(1) {
    width: 24px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform: rotate(45deg);
  }
  #globalHeader #menuBtn.isOpen span:nth-child(2) {
    opacity: 0;
  }
  #globalHeader #menuBtn.isOpen span:nth-child(3) {
    width: 24px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform: rotate(-45deg);
  }
  #globalHeader .ghMenu {
    display: none;
    width: 100%;
    height: 100%;
    padding: 0 20px 103px;
    background: #34405b;
    position: fixed;
    top: 60px;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #globalHeader .ghMenu .ghNavList {
    margin: 0 -20px;
    border-bottom: 1px solid #35415f;
  }
  #globalHeader .ghMenu .ghNavList > li + li {
    border-top: 1px solid #35415f;
  }
  #globalHeader .ghMenu .ghNavList > li > p {
    padding: 15px 40px 14px 20px;
    font-size: 14px;
    background: #242e44;
    transition: 0.2s background;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > p:not(.notList) > * {
    pointer-events: none;
  }
  #globalHeader .ghMenu .ghNavList > li > p:not(.notList):before, #globalHeader .ghMenu .ghNavList > li > p:not(.notList):after {
    content: "";
    display: block;
    width: 13px;
    height: 2px;
    background: #fff;
    position: absolute;
    right: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList > li > p:not(.notList):after {
    transform: rotate(90deg);
    transition: transform 0.2s;
  }
  #globalHeader .ghMenu .ghNavList > li > p:not(.notList).open {
    background: #c90056;
  }
  #globalHeader .ghMenu .ghNavList > li > p:not(.notList).open:after {
    transform: rotate(0deg);
  }
  #globalHeader .ghMenu .ghNavList > li > p > a {
    margin: -15px -40px -14px -20px;
    padding: 15px 50px 14px 20px;
  }
  #globalHeader .ghMenu .ghNavList > li > p > a[target=_blank]:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(../images/common/icon_blank_white.svg) no-repeat center;
    background-size: 17px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 22px;
    transform: translateY(-1px);
  }
  #globalHeader .ghMenu .ghNavList > li > p > a:not([target=_blank]):before {
    content: "";
    display: block;
    width: 7px;
    height: 12px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 28px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * {
    font-size: 14px;
    color: #fff;
  }
  #globalHeader .ghMenu .ghNavList > li > a {
    display: block;
    padding: 15px 40px 14px 20px;
    font-size: 14px;
    color: #fff;
    background: #242e44;
    transition: 0.2s background;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > a:before {
    content: "";
    display: block;
    width: 8px;
    height: 13px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem {
    display: none;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghNavListItemTitle a {
    display: block;
    padding: 11px 40px 12px 35px;
    color: #fff;
    font-size: 13px;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghNavListItemTitle a:before {
    content: "";
    display: block;
    width: 8px;
    height: 13px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn {
    display: none !important;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > p:not(.pickupTitle) {
    display: none !important;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb {
    display: none;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea > p {
    display: none;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .pickupTitle {
    border-top: 1px solid #35415f;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .pickupTitle a {
    display: block;
    padding: 11px 40px 12px 35px;
    color: #fff;
    font-size: 13px;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .pickupTitle a:before {
    content: "";
    display: block;
    width: 8px;
    height: 13px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li + li,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li > ul > li + li,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkList > li + li {
    border-top: 1px solid #35415f;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li > a,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li > ul > li > a,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkList > li > a {
    display: block;
    padding: 11px 40px 12px 35px;
    color: #fff;
    font-size: 13px;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li > a:before,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupList > li > ul > li > a:before,
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkList > li > a:before {
    content: "";
    display: block;
    width: 8px;
    height: 13px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb > a {
    display: block;
    padding: 11px 40px 12px 35px;
    color: #fff;
    font-size: 13px;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb > a:before {
    content: "";
    display: block;
    width: 8px;
    height: 13px;
    background: url(../images/common/icon_arrow_white.svg) no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghOtherList {
    border-top: 1px solid #35415f;
  }
  #globalHeader .ghMenu .ghList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 30px;
  }
  #globalHeader .ghMenu .ghList > li.ghListContact {
    width: 100%;
    margin-bottom: 7px;
  }
  #globalHeader .ghMenu .ghList > li.ghListDocument {
    width: calc(50% - 4px);
  }
  #globalHeader .ghMenu .ghList > li.ghListRecruit {
    width: calc(50% - 4px);
  }
  #globalHeader .ghMenu .ghList > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 13px 10px 16px;
    color: #fff;
    font-size: 14px;
  }
  #globalHeader .ghMenu .ghList > li > a .iconContact {
    width: 18px;
    height: 12px;
    margin-right: 9px;
  }
  #globalHeader .ghMenu .ghList > li > a .iconDocument {
    width: 14px;
    height: 18px;
    margin-right: 10px;
  }
  #globalHeader .ghMenu .ghList > li > a .iconCad {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    display: inline-block;
    background: url(../images/common/icon_cad.svg) no-repeat center;
    background-size: contain;
  }
  #globalHeader .ghMenu .ghLanguage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 25px;
  }
  #globalHeader .ghMenu .ghLanguage > li {
    font-weight: 500;
    position: relative;
  }
  #globalHeader .ghMenu .ghLanguage > li a {
    padding: 0 15px;
    color: #fff;
    letter-spacing: 0.06em;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearchBtn {
    border-image-source: none !important;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch {
    display: block !important;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input {
    height: 36px;
    background-position: left 10px center !important;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input {
    padding-left: 10px !important;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-search-button-v2 {
    width: 45px;
    height: 36px;
    padding: 0 10px;
    cursor: pointer;
  }
  #footContact {
    padding: 53px 35px 59px;
  }
  #footContact .inner {
    max-width: 700px;
    margin: 0 auto;
  }
  #footContact .inner .footContactNotes {
    margin-bottom: 35px;
  }
  .sectionHeader {
    margin-bottom: 21px;
  }
  .sectionHeader .sectionTitle {
    margin-bottom: 8px;
    font-size: 24px;
  }
  .sectionHeader .sectionTitleSub {
    font-size: 12px;
  }
  .pickupListItem {
    padding-bottom: 25px;
    margin-bottom: 0;
  }
  .pickupListItem .pickupItem {
    width: calc(100vw - 140px);
    margin: 0 15px;
  }
  .pickupListItem .pickupItem > a {
    display: block;
    padding-top: 44.13vw;
  }
  .pickupListItem .pickupItem > a .pickupItemThumb {
    height: 51.33vw;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  .pickupListItem .pickupItem > a .pickupItemThumb img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
  .pickupListItem .pickupItem > a .pickupItemText {
    position: relative;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag {
    min-height: 24px;
    padding: 10px 30px 0 25px;
    font-size: 14px;
    z-index: 10;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag:before {
    width: 21px;
    bottom: 6px;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemNote {
    min-height: 73px;
    padding: 14px 25px 17px;
    margin-top: -7px;
    font-size: 14px;
    line-height: 1.6196;
  }
  .pickupListItem .slick-dots {
    bottom: 0;
  }
  .pickupListItem .slick-dots > li {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
  .pickupListItem .slick-dots > li button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50%;
    background: #34405b;
  }
  .pickupListItem .slick-dots > li.slick-active button {
    background: #c90056;
  }
  #globalFooter {
    display: flex;
    flex-direction: column;
  }
  #globalFooter .gfSitemap {
    display: flex;
    flex-direction: column;
  }
  /*
  :target {
   @if $type == sp{
    animation: anchor_sp  0.1s;
   }
  	@if $type == pcHeaderTb{
    animation: anchor_tb  0.1s;
   }
  	@if $type == pcHeader{
    background: #000;
    animation: anchor_pc  0.1s;
   }
  }

  @keyframes  anchor_sp{
   0% { padding-top: 120px; margin-top: -120px; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_tb{
   0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_pc{
   0% { padding-top: 226px; margin-top: -226px; }
   100% { padding-top: 0; margin-top: 0; }
  }
  */
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 980px) {
  #globalFooter .gfSitemap .gfAddress {
    order: 1;
    padding: 20px 15px;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 980px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li + li {
    margin-top: 18px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li:nth-child(1) a {
    max-width: 222px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li:nth-child(2) a {
    max-width: 162px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li > a {
    margin: 0 auto;
  }
  #globalFooter .gfSitemap .gfAddress .gfAddressNote {
    max-width: 300px;
    margin: 16px auto 0;
    font-size: 14px;
    line-height: 1.5;
  }
  #globalFooter .gfSitemap .gfSitemapList {
    order: 0;
    border-top: 1px solid #e2e2e2;
    background: #fff;
  }
  #globalFooter .gfSitemap .gfSitemapList > li:not(.isActive) {
    display: none;
  }
  #globalFooter .gfSitemap .gfSitemapList a:before {
    content: "";
    display: block;
    width: 7px;
    height: 12px;
    background: url(../images/common/icon_arrow_black.svg) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
  #globalFooter .gfSitemap .gfSitemapList .gfSitemapListTitle {
    font-weight: bold;
    font-size: 14px;
  }
  #globalFooter .gfSitemap .gfSitemapList .gfSitemapListTitle > a {
    display: block;
    padding: 13px 35px 13px 20px;
    position: relative;
  }
  #globalFooter .gfSitemap .gfSitemapList .gfSitemapListItem {
    border-top: 1px solid #e2e2e2;
  }
  #globalFooter .gfSitemap .gfSitemapList .gfSitemapListItem > li > a {
    display: block;
    padding: 13px 35px 13px 35px;
    font-size: 13px;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 980px) {
  #globalFooter .gfSub {
    order: 2;
    background: #ebf0f4;
  }
}
@media screen and (max-width: 980px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
@media screen and (max-width: 980px) {
  #globalFooter .gfSub .gfNav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% - 70px);
    padding: 22px 0;
    margin: 0 auto;
  }
  #globalFooter .gfSub .gfNav > li {
    margin: 7px 0;
  }
  #globalFooter .gfSub .gfNav > li:before, #globalFooter .gfSub .gfNav > li:after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    background: #c1c1c1;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #globalFooter .gfSub .gfNav > li:before {
    left: -1px;
  }
  #globalFooter .gfSub .gfNav > li:after {
    right: 0;
  }
  #globalFooter .gfSub .gfNav > li > a {
    padding: 0 10px;
    font-size: 12px;
  }
  #globalFooter .gfSub small {
    display: block;
    text-align: center;
    min-height: 49px;
    padding: 15px 55px 16px;
    border-top: 1px solid #283148;
    font-size: 11px;
  }
  #globalFooter #pageTop {
    width: 50px;
    height: 49px;
    background-size: contain;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  #globalFooter #pageTop:before {
    width: 18px;
    height: 14px;
  }
  .cookieConsentLayout {
    width: 100%;
    padding: 50px 0 20px;
    font-size: 13px;
    font-weight: 600;
    left: 0;
    bottom: 0;
  }
  .cookieConsentLayout .cookieInner {
    padding: 0 20px;
  }
  .cookieConsentLayout .cookieBtn {
    margin-top: 15px;
  }
  .cookieConsentLayout .cookieBtn > a {
    display: inline-block;
    padding: 6px 18px;
    font-size: 14px;
    margin: 0 auto;
  }
  .cookieConsentLayout .cookieBtn > a + .btn {
    margin-top: 18px;
  }
  .cookieConsentLayout .cookieClose {
    right: 20px;
    top: 15px;
  }
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 981px) {
  body {
    line-height: 1.87;
    font-size: 16px;
  }
  .tel {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
  .btnWrap {
    line-height: 1.87 !important;
  }
  .postContent .btnWrap a,
  .commonBtn {
    display: inline-block;
  }
  .postContent .btnWrap a > div > [class^=icon],
  .commonBtn > div > [class^=icon] {
    transform: translateY(-2px);
  }
  .postContent .btnWrap a.btnS > div > [class^=icon],
  .commonBtn.btnS > div > [class^=icon] {
    transform: translateY(0);
  }
  .postContent .btnWrap a > [class^=icon],
  .commonBtn > [class^=icon] {
    right: 11px;
  }
  .postContent .btnWrap a + .commonBtn,
  .commonBtn + .commonBtn {
    margin-left: 15px;
  }
  .postContent .btnWrap a.btnBlock,
  .commonBtn.btnBlock {
    min-width: inherit !important;
  }
  .postContent .btnWrap a:not(.btnBlock),
  .commonBtn:not(.btnBlock) {
    min-width: 390px;
  }
  .postContent .btnWrap a.btnL > div,
  .commonBtn.btnL > div {
    padding: 20px 8px;
  }
  .postContent .btnWrap a.btnS,
  .commonBtn.btnS {
    min-width: 160px;
  }
  .postContent .btnWrap a.btnS > div,
  .commonBtn.btnS > div {
    padding: 4px 8px;
    font-size: 15px;
  }
  .postContent .btnWrap a.btnM,
  .commonBtn.btnM {
    min-width: 300px;
  }
  .postContent .btnWrap a.btnM > div,
  .commonBtn.btnM > div {
    padding: 11px 16px;
    font-size: 15px;
  }
  .postContent .btnWrap a:before,
  .commonBtn:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s width;
    z-index: 0;
  }
  .postContent .btnWrap a:hover:before,
  .commonBtn:hover:before {
    width: 100%;
  }
  .postContent .btnWrap a.btnS > div,
  .commonBtn.btnS > div {
    padding: 7px 8px 8px;
    min-width: inherit;
  }
  .postContent .btnWrap a.btnBorderPink:hover,
  .commonBtn.btnBorderPink:hover {
    color: #fff;
  }
  .postContent .btnWrap a.btnBorderPink:before,
  .commonBtn.btnBorderPink:before {
    background: #c90056;
  }
  .postContent .btnWrap a.btnBorderNavy:hover,
  .commonBtn.btnBorderNavy:hover {
    color: #fff;
  }
  .postContent .btnWrap a.btnBorderNavy:before,
  .commonBtn.btnBorderNavy:before {
    background: #34405b;
  }
  .postContent .btnWrap a.btnBack,
  .commonBtn.btnBack {
    min-width: 260px;
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .postContent .btnWrap a.btnBack:before,
  .commonBtn.btnBack:before {
    background: #c8c8c8;
  }
  .postContent .btnWrap a.iconFixedDash div:before,
  .commonBtn.iconFixedDash div:before {
    width: 10px;
    height: 11px;
    left: 15px;
  }
  .postContent .btnWrap a [class^=iconFixed],
  .commonBtn [class^=iconFixed] {
    width: 10px;
    height: 11px;
    left: 25px;
  }
  .btnBackWrap {
    margin-top: 30px;
  }
  .iconTel {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  .iconMail {
    width: 20px;
    height: 13px;
    margin-right: 12px;
  }
  .iconDashWhite {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashWhiteTop {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashBlack {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashGray {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDashPink {
    width: 10px;
    height: 11px;
    margin-right: 12px;
  }
  .iconDownload {
    width: 18px;
    height: 16px;
    margin-right: 12px;
  }
  .iconCad {
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }
  .iconBlank,
  .iconBlankBlack,
  .iconBlankPink {
    width: 17px;
    height: 17px;
    margin-right: 12px;
  }
  .iconPdf,
  .iconPdfWhite {
    width: 17px;
    height: 22px;
    margin-right: 12px;
  }
  .iconWord,
  .iconWordWhite {
    width: 17px;
    height: 22px;
    margin-right: 12px;
  }
  .iconExcel,
  .iconExcelWhite {
    width: 17px;
    height: 22px;
  }
  @keyframes isUsual {
    0% {
      position: fixed;
      top: 0;
    }
    100% {
      position: absolute;
      top: 0;
    }
  }
  @keyframes isFixed {
    0% {
      top: -120px;
    }
    100% {
      top: 0;
    }
  }
  #globalHeader {
    font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  }
  #globalHeader .ghLogo {
    width: 16.64vw;
    max-width: 213px;
    margin-right: calc(3.14% - 1.88vw);
  }
  #globalHeader .ghLogo a {
    transition: 0.2s opacity;
  }
  #globalHeader .ghLogo a:hover {
    opacity: 0.6;
  }
  #globalHeader .ghLogo a img {
    transform: translateY(4px);
  }
  #globalHeader #menuBtn {
    display: none !important;
  }
  #globalHeader .ghMenu {
    display: flex !important;
    width: 100%;
  }
  #globalHeader .ghMenu .ghNavList {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  #globalHeader .ghMenu .ghNavList > li {
    display: flex;
    align-items: center;
  }
  #globalHeader .ghMenu .ghNavList > li.isActive > p > *,
  #globalHeader .ghMenu .ghNavList > li.isActive > *,
  #globalHeader .ghMenu .ghNavList > li .isCurrent > p > *,
  #globalHeader .ghMenu .ghNavList > li .isCurrent > * {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList > li.isActive > p > *:before,
  #globalHeader .ghMenu .ghNavList > li.isActive > *:before,
  #globalHeader .ghMenu .ghNavList > li .isCurrent > p > *:before,
  #globalHeader .ghMenu .ghNavList > li .isCurrent > *:before {
    width: 100%;
  }
  #globalHeader .ghMenu .ghNavList > li.isCurrent > p {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList > li + li:before {
    content: "";
    width: 1px;
    height: 15px;
    background: #cecece;
  }
  #globalHeader .ghMenu .ghNavList > li > p {
    z-index: 1;
  }
  #globalHeader .ghMenu .ghNavList > li > p > a:after {
    display: none;
  }
  #globalHeader .ghMenu .ghNavList > li > p > a[target=_blank] {
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > p > a[target=_blank]:before {
    content: "";
    display: inline-block;
    width: min(17px, 1.32vw);
    height: min(17px, 1.32vw);
    background: url(../images/common/icon_blank_black.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    left: inherit;
    margin: auto;
    right: -4px;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
    position: relative;
    transition: 0.2s color;
    position: relative;
    cursor: pointer;
  }
  #globalHeader .ghMenu .ghNavList > li > p > *:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList > li > p > *:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6.5px 0 6.5px;
    transform: translateX(10px);
    border-color: #e2e2e2 transparent transparent transparent;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * strong {
    display: flex;
    align-items: center;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * strong:before {
    content: "";
    display: block;
    height: 3px;
    background: #c90056;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 0;
    transition: 0.2s width;
  }
  #globalHeader .ghMenu .ghNavList > li > a {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 0 27px;
    text-align: center;
    line-height: 1.4;
    transition: 0.2s color;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > a:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList > li > a strong {
    display: flex;
    align-items: center;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList > li > a strong:before {
    content: "";
    display: block;
    height: 3px;
    background: #c90056;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 0;
    transition: 0.2s width;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem {
    display: none;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    position: absolute;
    background: #242e44;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner {
    max-width: calc(1080px + 12%);
    padding: 0 6%;
    margin: 0 auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    margin-bottom: 23px;
    border-bottom: 1px solid #fff;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghNavListItemTitle {
    font-size: 30px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghNavListItemTitle a {
    display: inline-block;
    color: #fff;
    transition: 0.2s color;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghNavListItemTitle a:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn {
    flex: none;
    padding: 11px 19px 11px 40px;
    font-size: 14px;
    color: #242e44;
    line-height: 1;
    background: #fff;
    position: relative;
    cursor: pointer;
    transition: 0.2s color;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:hover:before, #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:hover:after {
    background: #c90056;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:before, #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:after {
    content: "";
    display: block;
    width: 17px;
    height: 1px;
    background: #242e44;
    position: absolute;
    top: 18px;
    left: 18px;
    margin: auto;
    transition: 0.2s background;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:before {
    transform: rotate(45deg);
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavListItemHeader .ghCloseBtn:after {
    transform: rotate(-45deg);
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb {
    width: 21.7593%;
    overflow: hidden;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    font-size: 15px;
    transition: 0.2s background;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb a:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #34405b;
    opacity: 0.65;
    position: absolute;
    top: 0;
    margin: auto;
    left: -100%;
    transition: 0.2s left;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb a:hover:before {
    left: 0;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavThumb a p {
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea {
    width: 78.33%;
    padding: 2.6596vw 6.974% 1.383vw 7.0922%;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea > p {
    text-align: right;
    margin-top: 1.0638vw;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea > p > a {
    font-size: 14px;
    color: #fff;
    transition: 0.2s opacity;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea > p > a:hover {
    opacity: 0.7;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList {
    display: flex;
    flex-wrap: wrap;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li {
    width: calc(33.3% - 2px);
    margin-left: 2px;
    margin-top: 2px;
    overflow: hidden;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 50px;
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
    color: #34405b;
    font-size: 14px;
    line-height: 1.4;
    background: #fff;
    transition: 0.2s color;
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li > a:hover {
    color: #fff;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li > a:hover:before {
    left: 0;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li > a:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #c90056;
    position: absolute;
    top: 0;
    margin: auto;
    left: -100%;
    transition: 0.2s left;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea .ghNavLinkList > li > a > p {
    position: relative;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea {
    width: 100%;
    font-size: 15px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList.col3 > li {
    width: calc(33% - 28px);
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList.col3 > li:not(:nth-child(4n)) {
    margin-right: 0;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList.col3 > li:not(:nth-child(3n)) {
    margin-right: 45px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li {
    width: calc(25% - 34px);
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li:not(:nth-child(4n)) {
    margin-right: 45px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > p {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li a {
    color: #fff;
    line-height: 1.4;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li a:hover .pickupAreaThumb img {
    transform: scale(1.1);
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li a:hover p {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > * + ul {
    margin-top: 20px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > ul > li + li {
    margin-top: 12px;
    line-height: 1.466;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > ul > li > a {
    display: inline-block;
    padding-left: 17px;
    font-size: 15px;
    position: relative;
    transition: 0.2s color;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > ul > li > a:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li > ul > li > a:before {
    content: "";
    display: block;
    width: 9px;
    height: 12px;
    background: url(../images/common/icon_dash_white.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 0;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb {
    display: block;
    margin-bottom: 8px;
    position: relative;
    font-weight: 400;
    overflow: hidden;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb img {
    display: block;
    width: 100%;
    object-fit: cover;
    transition: 0.2s transform;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb + p {
    font-size: 18px;
    font-weight: bold;
    transition: 0.2s color;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb + p a {
    transition: 0.2s color;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb + p a:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghList {
    width: 3.0435vw;
    height: 13.041vw;
    position: fixed;
    right: 0;
    margin: auto;
    z-index: 100;
  }
  #globalHeader .ghMenu .ghList > li + li {
    margin-top: 5px;
  }
  #globalHeader .ghMenu .ghList > li.ghListContact a:hover {
    background: #ab002e;
  }
  #globalHeader .ghMenu .ghList > li.ghListDocument a:hover {
    background: #3c3486;
  }
  #globalHeader .ghMenu .ghList > li.ghListRecruit a:hover {
    background: #00509b;
  }
  #globalHeader .ghMenu .ghList > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    line-height: 1.173;
    transition: 0.2s background;
  }
  #globalHeader .ghMenu .ghList > li > a .iconContact {
    display: none;
  }
  #globalHeader .ghMenu .ghList > li > a .iconDocument {
    display: none;
  }
  #globalHeader .ghMenu .ghList > li > a .iconCad {
    display: none;
  }
  #globalHeader .ghMenu .ghList > li > a > span {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.04em;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
  #globalHeader .ghMenu .ghLanguage {
    display: flex;
    align-items: center;
    margin-right: 3px;
  }
  #globalHeader .ghMenu .ghLanguage > li {
    display: flex;
    align-items: center;
  }
  #globalHeader .ghMenu .ghLanguage > li:after {
    display: inline-block;
    height: 15px;
  }
  #globalHeader .ghMenu .ghLanguage > li a {
    display: inline-block;
    font-weight: 500;
    padding: 0 14px;
    font-size: 15px;
    transition: 0.2s color;
    letter-spacing: 0.08em;
  }
  #globalHeader .ghMenu .ghLanguage > li a:hover {
    color: #c90056;
  }
  #globalHeader .ghMenu .ghSearchWrap {
    display: flex;
    height: 113px;
    margin-left: 6px;
    position: relative;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearchBtn {
    width: 20px;
    height: 21px;
    margin: auto;
    cursor: pointer;
    transition: opacity;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearchBtn:hover {
    opacity: 0.7;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch {
    display: none;
    width: 400px;
    padding: 16px 24px;
    height: 72px;
    background: #34405b;
    position: absolute;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input {
    height: 38px;
    background-position: left 10px center !important;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input {
    padding-left: 10px !important;
    font-size: 1.23vw;
    font-size: clamp(1px, 1.23vw, 15px);
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-search-button-v2 {
    width: 40px;
    height: 38px;
    padding: 9px 10px 8px;
    cursor: pointer;
  }
  #footContact {
    padding-top: 88px;
    padding-bottom: 82px;
  }
  #footContact .inner {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
  }
  #footContact .inner .footContactNotes {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .sectionHeader {
    margin-bottom: 26px;
  }
  .sectionHeader .sectionTitle {
    margin-bottom: 2px;
    font-size: 30px;
  }
  .sectionHeader .sectionTitleSub {
    font-size: 14px;
  }
  .pickupListItem {
    display: flex;
    width: 74.4167%;
  }
  .pickupListItem .pickupItem {
    display: flex;
    flex-direction: column;
    width: 31.5789%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.0023);
  }
  .pickupListItem .pickupItem:not(:first-child) {
    margin-left: 2.57%;
  }
  .pickupListItem .pickupItem > a {
    display: flex;
    height: 100%;
    padding-top: 150px;
    z-index: 1;
  }
  .pickupListItem .pickupItem > a:hover .pickupItemThumb {
    transform: scale(1.1);
  }
  .pickupListItem .pickupItem > a .pickupItemThumb {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transition: 0.3s transform;
    z-index: 0;
  }
  .pickupListItem .pickupItem > a .pickupItemThumb img {
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 0;
  }
  .pickupListItem .pickupItem > a .pickupItemText {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag {
    min-height: 24px;
    padding: 10px 25px 0 20px;
    font-size: 14px;
    z-index: 10;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemTag:before {
    width: 16px;
    bottom: 6px;
  }
  .pickupListItem .pickupItem > a .pickupItemText .pickupItemNote {
    height: calc(100% - 24px);
    padding: 14px 24px 17px;
    margin-top: -7px;
    font-size: 14px;
    line-height: 1.5;
  }
  #globalFooter {
    width: 100%;
    background: #ebf0f4;
  }
  #globalFooter .gfSitemap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 70px 0 55px;
  }
  /*
  :target {
   @if $type == sp{
    animation: anchor_sp  0.1s;
   }
  	@if $type == pcHeaderTb{
    animation: anchor_tb  0.1s;
   }
  	@if $type == pcHeader{
    background: #000;
    animation: anchor_pc  0.1s;
   }
  }

  @keyframes  anchor_sp{
   0% { padding-top: 120px; margin-top: -120px; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_tb{
   0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_pc{
   0% { padding-top: 226px; margin-top: -226px; }
   100% { padding-top: 0; margin-top: 0; }
  }
  */
  /* ココから下には記入禁止 */
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 1640px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print, screen and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress {
    order: 1;
    width: 100%;
    margin: 73px auto 0;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
@media print, screen and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li {
    margin: 0 50px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li:nth-child(1) img {
    max-width: 222px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li:nth-child(2) img {
    max-width: 162px;
  }
  #globalFooter .gfSitemap .gfAddress .gfLogoList > li > a img {
    display: block;
    margin: 0 auto;
  }
  #globalFooter .gfSitemap .gfAddress .gfAddressNote {
    font-size: 15px;
    line-height: 1.6;
  }
  #globalFooter .gfSitemap .gfSitemapList {
    display: flex;
    width: 100%;
    max-width: 1360px;
    padding-right: 80px;
    padding-left: 80px;
    margin: 0 auto;
  }
  #globalFooter .gfSitemap .gfSitemapList > li {
    margin: 0 auto;
    padding: 0 10px;
  }
  #globalFooter .gfSitemap .gfSitemapList > li a {
    transition: 0.2s opacity;
  }
  #globalFooter .gfSitemap .gfSitemapList > li a:hover {
    opacity: 0.7;
  }
  #globalFooter .gfSitemap .gfSitemapList > li .gfSitemapListTitle {
    font-size: 15px;
    font-weight: bold;
  }
  #globalFooter .gfSitemap .gfSitemapList > li .gfSitemapListTitle + .gfSitemapListTitle {
    margin-top: 24px;
  }
  #globalFooter .gfSitemap .gfSitemapList > li .gfSitemapListItem {
    margin-top: 24px;
    font-size: 12px;
  }
  #globalFooter .gfSitemap .gfSitemapList > li .gfSitemapListItem li + li {
    margin-top: 8px;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
@media print, screen and (min-width: 981px) {
  #globalFooter .gfSub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 8px;
    min-height: 66px;
    border-top: 1px solid #283148;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
@media print, screen and (min-width: 981px) {
  #globalFooter .gfSub .gfNav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    order: 1;
  }
  #globalFooter .gfSub .gfNav > li {
    display: flex;
    align-items: center;
  }
  #globalFooter .gfSub .gfNav > li + li:before {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    background: #333333;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #globalFooter .gfSub .gfNav > li > a {
    padding: 0 10px;
    font-size: 12px;
    transition: 0.2s opacity;
    vertical-align: middle;
  }
  #globalFooter .gfSub .gfNav > li > a:hover {
    opacity: 0.5;
  }
  #globalFooter .gfSub small {
    font-size: 11px;
    flex: none;
  }
  #globalFooter #pageTop {
    width: 67px;
    height: 65px;
    cursor: pointer;
    transition: transform 0.2s;
    background-size: contain;
    right: 80px;
    top: -35px;
  }
  #globalFooter #pageTop:hover {
    transform: translateY(-15px);
  }
  #globalFooter #pageTop:before {
    width: 18px;
    height: 14px;
  }
  .cookieConsentLayout {
    width: 100%;
    padding: 20px 0;
    left: 0;
    bottom: 0;
  }
  .cookieConsentLayout .cookieInner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding-right: 100px;
    padding-left: 100px;
    margin-inline: auto;
  }
  .cookieConsentLayout .cookieText {
    width: calc(100% - 203px);
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.05em;
  }
  .cookieConsentLayout .cookieText a:hover, .cookieConsentLayout .cookieText a:focus {
    text-decoration: none;
  }
  .cookieConsentLayout .cookieBtn {
    flex: none;
    margin-top: 0;
    margin-left: 30px;
  }
  .cookieConsentLayout .cookieBtn > a {
    display: block;
    padding: 6px 18px;
    font-size: 15px;
    transition: 0.4s background, 0.4s color;
    cursor: pointer;
  }
  .cookieConsentLayout .cookieBtn > a:focus, .cookieConsentLayout .cookieBtn > a:hover {
    color: #fff;
    background: #13192a;
  }
  .cookieConsentLayout .cookieBtn > a > span {
    position: relative;
    transition: 0.4s opacity;
  }
  .cookieConsentLayout .cookieClose {
    right: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .cookieConsentLayout .cookieClose:focus span, .cookieConsentLayout .cookieClose:hover span {
    opacity: 0.7;
  }
  .cookieConsentLayout .cookieClose > span {
    transition: 0.4s opacity;
  }
  .forSP {
    display: none !important;
  }
}
@media print, screen and (min-width: 981px) and (max-width: 1639px) {
  main {
    padding-top: 8.4722vw;
  }
  @keyframes isUsual {
    0% {
      position: fixed;
      top: 0;
    }
    100% {
      position: absolute;
      top: 0;
    }
  }
  @keyframes isFixed {
    0% {
      top: -120px;
    }
    100% {
      top: 0;
    }
  }
  #globalHeader {
    height: 8.4722vw;
  }
  #globalHeader .ghLogo {
    width: 14.7917vw;
    position: absolute;
    top: 1.5972vw;
    left: 1.6667vw;
  }
  #globalHeader .ghLogo a img {
    transform: translateY(0);
  }
  #globalHeader .ghMenu .ghNavList {
    margin-top: 4.5139vw;
    padding-left: 6%;
  }
  #globalHeader .ghMenu .ghNavList > li {
    height: 1.0417vw;
  }
  #globalHeader .ghMenu .ghNavList > li > p {
    height: 3.9583vw;
    cursor: pointer;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * {
    font-size: 1.0417vw;
    font-size: clamp(1px, 1.23vw, 15px);
    height: 3.9583vw;
    padding: 0 1.4056vw;
    line-height: 1.2;
  }
  #globalHeader .ghMenu .ghNavList > li > p > *:after {
    border-width: 0.4167vw 0.4514vw 0 0.4514vw;
    transform: translateX(0.6944vw);
  }
  #globalHeader .ghMenu .ghNavList > li > p > * strong {
    height: 3.9583vw;
  }
  #globalHeader .ghMenu .ghNavList > li > a {
    height: 3.9583vw;
    font-size: 1.0417vw;
    font-size: clamp(1px, 1.23vw, 15px);
    padding: 0 1.8056vw;
  }
  #globalHeader .ghMenu .ghNavList > li > a strong {
    height: 3.9583vw;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem {
    top: 8.4722vw;
    left: 0;
  }
  #globalHeader .ghMenu .ghList {
    width: 56px;
    height: 240px;
    top: 0;
    margin-top: calc(13vw - 90px);
    transform: translateY(8.4722vw);
  }
  #globalHeader .ghMenu .ghLanguage {
    position: absolute;
    top: 1.975vw;
    right: 3.8194vw;
  }
  #globalHeader .ghMenu .ghLanguage > li:after {
    height: 1.0417vw;
  }
  #globalHeader .ghMenu .ghLanguage > li a {
    padding: 0 0.9722vw;
    font-size: clamp(1px, 1.23vw, 15px);
  }
  #globalHeader .ghMenu .ghSearchWrap {
    margin-left: 0;
    height: inherit;
    position: absolute;
    top: 2.2222vw;
    right: 1.6667vw;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearchBtn {
    width: clamp(10px, 1.3889vw, 20px);
    height: clamp(10px, 1.4583vw, 21px);
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch {
    position: fixed;
    top: 8.4722vw;
    right: 6.8611vw;
  }
  /*
  :target {
   @if $type == sp{
    animation: anchor_sp  0.1s;
   }
  	@if $type == pcHeaderTb{
    animation: anchor_tb  0.1s;
   }
  	@if $type == pcHeader{
    background: #000;
    animation: anchor_pc  0.1s;
   }
  }

  @keyframes  anchor_sp{
   0% { padding-top: 120px; margin-top: -120px; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_tb{
   0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_pc{
   0% { padding-top: 226px; margin-top: -226px; }
   100% { padding-top: 0; margin-top: 0; }
  }
  */
  /* ココから下には記入禁止 */
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (max-width: 1639px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 1640px), screen and (min-width: 981px) and (max-width: 1639px) and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (max-width: 1639px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (max-width: 1639px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 981px) and (max-width: 1639px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
@media print, screen and (min-width: 1640px) {
  main {
    padding-top: 113px;
  }
  @keyframes isUsual {
    0% {
      position: fixed;
      top: 0;
    }
    100% {
      position: absolute;
      top: 0;
    }
  }
  @keyframes isFixed {
    0% {
      top: -120px;
    }
    100% {
      top: 0;
    }
  }
  #globalHeader {
    padding-left: 6%;
    padding-right: 45px;
  }
  #globalHeader .ghLogo {
    margin-right: calc(3.14% - 24px);
    position: absolute;
    top: 33px;
    left: 35px;
  }
  #globalHeader .ghMenu .ghNavList {
    justify-content: center;
    margin-top: 74px;
    padding-left: 0;
  }
  #globalHeader .ghMenu .ghNavList > li {
    height: 59px;
  }
  #globalHeader .ghMenu .ghNavList > li > p > * {
    padding: 0 24px;
    line-height: 1.4;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem {
    top: 132px;
    left: 0;
    right: 0;
    margin: auto;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea {
    padding: 50px 6.974% 26px 7.0922%;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghNavLinkArea > p {
    margin-top: 20px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea {
    font-size: 15px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb {
    margin-bottom: 11px;
  }
  #globalHeader .ghMenu .ghNavList .ghNavListItem > .inner .ghPickupArea .ghPickupList > li .pickupAreaThumb:before {
    font-size: 15px;
  }
  #globalHeader .ghMenu .ghList {
    width: 56px;
    height: 240px;
    top: 9.298vw;
    transform: translateY(113px);
  }
  #globalHeader .ghMenu .ghLanguage {
    position: absolute;
    top: 42px;
    right: 80px;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch {
    position: absolute;
    right: 0;
    bottom: -92px;
  }
  #globalHeader .ghMenu .ghSearchWrap .ghSearch .gsc-input {
    font-size: 15px;
  }
  /*
  :target {
   @if $type == sp{
    animation: anchor_sp  0.1s;
   }
  	@if $type == pcHeaderTb{
    animation: anchor_tb  0.1s;
   }
  	@if $type == pcHeader{
    background: #000;
    animation: anchor_pc  0.1s;
   }
  }

  @keyframes  anchor_sp{
   0% { padding-top: 120px; margin-top: -120px; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_tb{
   0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_pc{
   0% { padding-top: 226px; margin-top: -226px; }
   100% { padding-top: 0; margin-top: 0; }
  }
  */
  /* ココから下には記入禁止 */
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 1640px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 1640px), screen and (min-width: 1640px) and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 1640px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 1640px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
@media print and (-ms-high-contrast: none) and (min-width: 981px), screen and (min-width: 1640px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .postContent .btnWrap a.btnS > div,
  .commonBtn.btnS > div {
    padding: 4px 8px;
  }
  .postContent .btnWrap a.btnM > div,
  .commonBtn.btnM > div {
    padding: 11px 8px;
  }
  @keyframes isUsual {
    0% {
      position: fixed;
      top: 0;
    }
    100% {
      position: absolute;
      top: 0;
    }
  }
  @keyframes isFixed {
    0% {
      top: -120px;
    }
    100% {
      top: 0;
    }
  }
  /*
  :target {
   @if $type == sp{
    animation: anchor_sp  0.1s;
   }
  	@if $type == pcHeaderTb{
    animation: anchor_tb  0.1s;
   }
  	@if $type == pcHeader{
    background: #000;
    animation: anchor_pc  0.1s;
   }
  }

  @keyframes  anchor_sp{
   0% { padding-top: 120px; margin-top: -120px; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_tb{
   0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
   100% { padding-top: 0; margin-top: 0; }
  }

  @keyframes  anchor_pc{
   0% { padding-top: 226px; margin-top: -226px; }
   100% { padding-top: 0; margin-top: 0; }
  }
  */
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 1640px) {
  #globalFooter .gfSitemap {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  #globalFooter .gfSitemap .gfAddress {
    width: 100%;
    max-width: inherit;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfAddress .gfLogoList {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: center;
    width: 100%;
    max-width: inherit;
    margin-top: 60px;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSitemap .gfSitemapList {
    justify-content: flex-start !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) and (-ms-high-contrast: none) and (min-width: 981px) {
  #globalFooter .gfSub {
    height: 66px !important;
  }
}
@media screen and (min-width: 981px) and (max-width: 1280px) {
  .cookieConsentLayout .cookieText {
    width: 100%;
  }
  .cookieConsentLayout .cookieBtn {
    margin-left: 20px;
  }
  .cookieConsentLayout .cookieBtn > a {
    min-width: 40%;
  }
}