body{
	@if $type == ""{
		font-weight: 400;
		font-family: 'Noto Sans JP', sans-serif;
	}

 @if $type == sp{
  line-height: 1.5;
  font-size: 14px;

  &.isOpen {
   position: relative;
   overflow: hidden;

   &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: $c_navy;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: .8;
    overflow: hidden;
   }
  }
 }

 @if $type == pc{
  line-height: 1.87;
  font-size: 16px;
 }

 * {
  @if $type == ""{
   outline: none;
   backface-visibility: hidden !important;
   -webkit-backface-visibility: hidden !important;
  }
 }
}

.fontEn {
  @if $type == ""{
    @include fontRo;
   } 
}

a {
  @if $type == ""{
    color: #000000;
    text-decoration: none;
  }
}

ul,ol {
 @if $type == ""{
  list-style-type: none;
 }
}

img {
 @if $type == ""{
  max-width: 100%;
  height: auto;
		image-rendering: -webkit-optimize-contrast;
 }
}

* {
 @if $type == ""{
  box-sizing: border-box;
 }
}

.tel {
 @if $type == sp{
		color: #005ba4;
  text-decoration: underline;
 }

 @if $type == pc{
  color: inherit !important;
  text-decoration: none !important;
  pointer-events: none;
 }
}

sup,sub {
	@if $type == ""{
		font-size: .6em;
	}

}

.dInlineBlock {
 @if $type == ""{
  display: inline-block;
 }
 @if $type == sp{
  white-space: nowrap;
 }
}


// common
* + .btnWrap {
  @if $type == ""{
    margin-top: 3em;
   }
}

.btnWrap {
 @if $type == ""{
  text-align: center;
 }
	@if $type == sp{
		line-height: 1.5 !important;
 }
 @if $type == pc{
		line-height: 1.87 !important;
 }
}

.postContent .btnWrap a,
.commonBtn {
 @if $type == ""{
  text-align: center;
  vertical-align: middle;
  text-decoration: none !important;
  transition: .2s color, .2s background;
  position: relative;
		
		> div {
			position: relative;
		}
 }
 @if $type == sp{
  display: block;
 }
 @if $type == pc{
  display: inline-block;
  
  > div {
   > [class^=icon] {
    transform: translateY(-2px);
   }
  }

  &.btnS {
    > div {
      > [class^=icon] {
       transform: translateY(0);
      }
     }
  }
 }
	
	> [class^=icon] {
		@if $type == ""{
			margin-right: 0;
			position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
		}
		@if $type == sp{
			right: 11px;
		}
		@if $type == pc{
			right: 11px;
		}
	}

 + .commonBtn {
  @if $type == sp{
   margin-top: 15px;
  }
  @if $type == pc{
   margin-left: 15px;
  }
 }

	&.btnBlock {
		@if $type == sp{
		}
		@if $type == pc{
			min-width: inherit !important;
		}
	}
 &:not(.btnBlock) {
  @if $type == sp{
   display: block;
  }
  @if $type == pc{
   min-width: 390px;
  }
 }

 &.btnL {
  > div {
   @if $type == ""{
    position: relative;
   }
   @if $type == sp{
    padding: 15px 8px 15px;
    font-size: 14px;
   }
   @if $type == pc{
				padding: 20px 8px;
   }
  }
 }

 &.btnS {
  @if $type == pc{
    min-width: 160px;
  }

  > div {
    @if $type == ""{
     display: flex;
     align-items: center;
     justify-content: center;
     position: relative;
    }
    @if $type == sp{
     //padding: 15px 8px 15px;
     font-size: 14px;
    }
    @if $type == pc{
         padding: 4px 8px;
     font-size: 15px;
    }
       @if $type == tb{
         padding: 4px 8px;
       }
   } 
}

 &.btnM {
		@if $type == pc{
			min-width: 300px;
		}
		
  > div {
   @if $type == ""{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
   }
   @if $type == sp{
				padding: 15px 8px 15px;
    font-size: 14px;
   }
   @if $type == pc{
				padding: 11px 16px;
    font-size: 15px;
   }
			@if $type == tb{
				padding: 11px 8px;
			}
  } 
 }

 @if $type == pc{
  &:before {
   content: "";
   display: block;
   width: 0;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   transition: .2s width;
   z-index: 0;
  }

  &:hover {
   &:before {
    width: 100%;
   }
  }
 }
	
	&.btnS {
    > div {
      @if $type == sp{
        padding: 7px 8px 8px;
        min-width: inherit;
      }
      @if $type == pc{
        padding: 7px 8px 8px;
        min-width: inherit;
      }
    }
		
	}

 // btn color
 &.btnPink {
  @if $type == ""{
   color: #fff;
   background: $c_base;
   
   &:before{
    background: #ab002e;
   }
  }
 }  

 &.btnNavy {
  @if $type == ""{
   color: #fff;
   background: $c_navy;
   
   &:before{
    background: #13192a;
   }
  }

 }

 &.btnBorderPink {
  @if $type == ""{
   color: $c_base;
   border: 1px solid $c_base;
  }
  @if $type == pc{
   &:hover {
    color: #fff;
   }
   
   &:before{
    background: $c_base;
   }
  }
 } 
 
 &.btnBorderNavy {
  @if $type == ""{
   color: $c_navy;
   border: 1px solid $c_navy;
  }
  @if $type == pc{
   &:hover {
    color: #fff;
   }
   
   &:before{
    background: $c_navy;
   }
  }
 } 

 &.btnBlock {
  @if $type == ""{
   display: block;
   width: 100%;
  }
 }

	[class^="icon"] {
		@if $type == ""{
			vertical-align: middle;
		}
	}
 
 &.btnBack {
  @if $type == ""{
   color: #666666;
   background: #e2e2e2;
  }
  @if $type == sp{
   padding-top: 12px;
   padding-bottom: 12px;
  }
  @if $type == pc{
   min-width: 260px;
   padding-top: 22px;
   padding-bottom: 22px;
   
   &:before{
    background: #c8c8c8;
   }
  }
  
  [class^="icon"] {
   @if $type == ""{
    transform: scale(-1,1);
   }
  }
 }

 &.iconFixedDash {
  div {
   @if $type == ""{
   }
   @if $type == sp{
    padding: 18px 40px;
   }
   @if $type == pc{
   }

   &:before {
    @if $type == ""{
     content: "";
     display: block;
     background: url(../images/common/icon_dash_white.svg) no-repeat;
     background-size: contain;
     position: absolute;
     top: 0;
     bottom: 0;
     margin: auto;
    }
    @if $type == sp{
     width: 10px;
     height: 11px;
     left: 25px;
    }
    @if $type == pc{
     width: 10px;
     height: 11px;
     left: 15px;
    }
   }
  }
 }
	
	.iconPdf,
	.iconWord,
	.iconExcel {
		@if $type == ""{
			vertical-align: text-bottom;
		}
	}
	
	[class^="iconFixed"] {
		@if $type == ""{
			position: absolute;
		}
		@if $type == sp{
			width: 10px;
			height: 11px;
			left: 25px;
		}
		@if $type == pc{
			width: 10px;
			height: 11px;
			left: 25px;
		}
	}
}

.btnBackWrap {
 @if $type == ""{
 }
 @if $type == sp{
  margin-top: 20px;
 }
 @if $type == pc{
  margin-top: 30px;
 }
}



.iconTel {
 @if $type == ""{
  display: inline-block;
  background: url(../images/common/icon_tel.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
 }
 @if $type == sp{
  width: 18px;
  height: 18px;
  margin-right: 6px;
 }
 @if $type == pc{
  width: 18px;
  height: 18px;
  margin-right: 12px;
 }
}

.iconMail {
 @if $type == ""{
  display: inline-block;
  background: url(../images/common/icon_mail.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
 }
 @if $type == sp{
  width: 19px;
  height: 12px;
  margin-right: 6px;
 }
 @if $type == pc{
  width: 20px;
  height: 13px;
  margin-right: 12px;
 }
}

.iconDashWhite {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_white.svg) no-repeat;
  background-size: contain;
 }
 @if $type == sp{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
}

.iconDashWhiteTop {
  @if $type == ""{
    content: "";
    display: inline-block;
    background: url(../images/common/icon_dash_white.svg) no-repeat;
    background-size: contain;
    transform: rotate(-90deg) !important;
   }
   @if $type == sp{
    width: 10px;
    height: 11px;
    margin-right: 12px;
   }
   @if $type == pc{
    width: 10px;
    height: 11px;
    margin-right: 12px;
   }
}

.iconDashBlack {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_black.svg) no-repeat;
  background-size: contain;
 }
 @if $type == sp{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
}

.iconDashGray {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_gray.svg) no-repeat;
  background-size: contain;
 }
 @if $type == sp{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
}


.iconDashPink {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_dash_pink.svg) no-repeat;
  background-size: contain;
 }
 @if $type == sp{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 10px;
  height: 11px;
  margin-right: 12px;
 }
}

.iconDownload {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_download_white.svg) no-repeat center;
  background-size: contain;
 }
 @if $type == sp{
  width: 18px;
  height: 16px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 18px;
  height: 16px;
  margin-right: 12px;
 }
}

//.iconBook {
// @if $type == ""{
//  content: "";
//  display: inline-block;
//  background: url(../images/common/icon_book.svg) no-repeat center;
//  background-size: contain;
// }
// @if $type == sp{
//  width: 28px;
//  height: 19px;
//  margin-right: 12px;
// }
// @if $type == pc{
//  width: 28px;
//  height: 19px;
//  margin-right: 12px;
// }
//}

//.iconDocuments {
// @if $type == ""{
//  content: "";
//  display: inline-block;
//  background: url(../images/common/icon_documents.svg) no-repeat center;
//  background-size: contain;
// }
// @if $type == sp{
//  width: 26px;
//  height: 25px;
//  margin-right: 12px;
// }
// @if $type == pc{
//  width: 26px;
//  height: 25px;
//  margin-right: 12px;
// }
//}

//.iconInstructions {
// @if $type == ""{
//  content: "";
//  display: inline-block;
//  background: url(../images/common/icon_instructions.svg) no-repeat center;
//  background-size: contain;
// }
// @if $type == sp{
//  width: 18px;
//  height: 21px;
//  margin-right: 12px;
// }
// @if $type == pc{
//  width: 18px;
//  height: 21px;
//  margin-right: 12px;
// }
//}

//.iconSupport {
//	@if $type == ""{
//		content: "";
//		display: inline-block;
//		background: url(../images/common/icon_support.svg) no-repeat center;
//		background-size: contain;
//	}
//	@if $type == sp{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//	@if $type == pc{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//}

//.iconGreening {
//	@if $type == ""{
//		content: "";
//		display: inline-block;
//		background: url(../images/common/icon_greening.svg) no-repeat center;
//		background-size: contain;
//	}
//	@if $type == sp{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//	@if $type == pc{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//}

//.iconBusductLow {
//	@if $type == ""{
//		content: "";
//		display: inline-block;
//		background: url(../images/common/icon_busductlow.svg) no-repeat center;
//		background-size: contain;
//	}
//	@if $type == sp{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//	@if $type == pc{
//		width: 26px;
//		height: 25px;
//		margin-right: 12px;
//	}
//}

.iconCad {
 @if $type == ""{
  content: "";
  display: inline-block;
  background: url(../images/common/icon_cad.svg) no-repeat center;
  background-size: contain;
 }
 @if $type == sp{
  width: 22px;
  height: 22px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 22px;
  height: 22px;
  margin-right: 12px;
 }
}

.iconBlank,
.iconBlankBlack,
.iconBlankPink {
 @if $type == ""{
  content: "";
  display: inline-block;
 }
 @if $type == sp{
  width: 17px;
  height: 17px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 17px;
  height: 17px;
  margin-right: 12px;
 }
}
  
.iconBlank {
 @if $type == ""{
  background: url(../images/common/icon_blank_white.svg) no-repeat center;
  background-size: 17px;
 }
}

.iconBlankBlack {
 @if $type == ""{
  background: url(../images/common/icon_blank_black.svg) no-repeat center;
  background-size: 17px;
 }
}

.iconBlankPink {
 @if $type == ""{
  background: url(../images/common/icon_blank_pink.svg) no-repeat center;
  background-size: 17px;
 }
}

.iconPdf,
.iconPdfWhite{
	@if $type == ""{
		content: "";
		display: inline-block;
		background-size: 17px;
	}
	@if $type == sp{
		width: 17px;
		height: 22px;
		margin-right: 12px;
	}
	@if $type == pc{
		width: 17px;
		height: 22px;
		margin-right: 12px;
	}
}

.iconPdf {
 @if $type == ""{
  background: url(../images/common/icon_pdf.svg) no-repeat center;
 }
}

.iconPdfWhite {
	@if $type == ""{
		background: url(../images/common/icon_pdf_white.svg) no-repeat center;
	}
}

.iconWord,
.iconWordWhite{
 @if $type == ""{
  content: "";
  display: inline-block;
  background-size: 17px;
 }
 @if $type == sp{
  width: 17px;
  height: 22px;
  margin-right: 12px;
 }
 @if $type == pc{
  width: 17px;
  height: 22px;
  margin-right: 12px;
 }
}

.iconWord{
	@if $type == ""{
		background: url(../images/common/icon_word.svg) no-repeat center;
	}
}
.iconWordWhite{
	@if $type == ""{
		background: url(../images/common/icon_word_white.svg) no-repeat center;
	}
}

.iconExcel,
.iconExcelWhite {
 @if $type == ""{
  content: "";
  display: inline-block;
  background-size: 17px;
 }
 @if $type == sp{
  width: 17px;
  height: 22px;
 }
 @if $type == pc{
  width: 17px;
  height: 22px;
 }
}

.iconExcel {
	@if $type == ""{
		background: url(../images/common/icon_excel.svg) no-repeat center;
	}
}

.iconExcelWhite {
	@if $type == ""{
		background: url(../images/common/icon_excel_white.svg) no-repeat center;
	}
}


main {
 @if $type == ""{
 }
 @if $type == sp{
  padding-top: 60px;
 }
 @if $type == pcHeaderTb{
  padding-top: 8.4722vw;
 }
 @if $type == pcHeader{
  padding-top: 113px;
 }
}

//header
@keyframes isUsual {
	0% {
		position: fixed;
		top: 0;
	}
	100% {
		position: absolute;
		top: 0;
	}
}

@keyframes isFixed {
	0% {
		top: -120px;
	}
	100% {
		top: 0;
	}
}


#globalHeader {
 @if $type == ""{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transition: .2s all;
		
		&.isUsual {
			animation: isUsual .2s both;
		}
		
		&.isFixed {
			position: fixed;
   border-bottom: 1px solid #b8b8b8;
			animation: isFixed .2s both;
		}
		
 }
 @if $type == sp{
  height: 60px;
  padding-left: 20px;
 }
 @if $type == pc{
  @include fontHi;
 }
 @if $type == pcHeader{
  padding-left: 6%;
  padding-right: 45px;
 }
 @if $type == pcHeaderTb{
  height: 8.4722vw;
 }

 .ghLogo {
  @if $type == ""{
  }
  @if $type == sp{
   width: 50%;
   max-width: 180px;
   margin-right: auto;
  }
  @if $type == pc{
   width: 16.64vw;
   max-width: 213px;
   margin-right: calc(3.14% - 1.88vw);
  }
  @if $type == pcHeader{
   margin-right: calc(3.14% - 24px);
   position: absolute;
   top: 33px;
   left: 35px;
  }
  @if $type == pcHeaderTb{
   width: 14.7917vw;
   position: absolute;
   top: 1.5972vw;
   left: 1.6667vw;
  }

  a {
   @if $type == ""{
    display: block;
   }
   @if $type == sp{
   }
   @if $type == pc{
    transition: .2s opacity;

    &:hover {
     opacity: .6;
    }
   }

   img {
    @if $type == ""{
     image-rendering: -webkit-optimize-contrast;
    }
    @if $type == sp{
     transform: translateY(-2px);
    }
    @if $type == pc{
     transform: translateY(4px);
    }
    @if $type == pcHeaderTb{
     transform: translateY(0);
    }
   }
  }
 }

 #menuBtn {
  @if $type == ""{
  }
  @if $type == sp{
   display: block;
   width: 60px;
   height: 60px;
   background: $c_navy;
   position: relative;
   flex: none;

   > span {
    display: block;
    height: 2px;
    border-radius: 6px;
    background: #fff;
    position: absolute;
    transition: .2s opacity, .2s transform;

    &:nth-child(1) {
     width: 24px;
     top: 22px;
     right: 18px;
    }

    &:nth-child(2) {
     width: 18px;
     top: 0;
     bottom: 0;
     right: 18px;
     margin: auto;
    }

    &:nth-child(3) {
     width: 12px;
     bottom: 22px;
     right: 18px;
    }
   }

   &.isOpen {
    span {
     &:nth-child(1) {
      width: 24px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      transform: rotate(45deg);
     }

     &:nth-child(2) {
      opacity: 0;
     }

     &:nth-child(3) {
      width: 24px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      transform: rotate(-45deg);
     }
    }
   }
  }

  @if $type == pc{
   display: none !important;
  }
 }

 .ghMenu {
  @if $type == ""{
  }
  @if $type == sp{
   display: none;
   width: 100%;
   height: 100%;
   padding: 0 20px 103px;
   background: $c_navy;
   position: fixed;
   top: 60px;
   left: 0;
   overflow-y: scroll;
   overflow-x: hidden;
  }
  @if $type == pc{
   display: flex !important;
   width: 100%;
  }

  .ghNavList {
   @if $type == ""{
   }
   @if $type == sp{
    margin: 0 -20px;
    border-bottom: 1px solid #35415f;
   }
   @if $type == pc{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
   }
   @if $type == pcHeaderTb{
    margin-top: 4.5139vw;
    padding-left: 6%;
   }
   @if $type == pcHeader {
    justify-content: center;
    margin-top: 74px;
    padding-left: 0;
   }

   > li {
    @if $type == ""{
    }
    @if $type == sp{
     + li {
      border-top: 1px solid #35415f;
     }
    }
    @if $type == pc{
     display: flex;
     align-items: center;

     &.isActive,
     .isCurrent {
      > p > *,
      > * {
       color: $c_base;
      }
      > p > *:before,
      > *:before {
       width: 100%;
      }
     }

     &.isCurrent {
      > p {
       color: $c_base;
      }
     }
     
     + li {
      &:before {
       content: "";
       width: 1px;
       height: 15px;
       background: #cecece;
      }
     }
    }
    @if $type == pcHeader{
     height: 59px;
    }
    @if $type == pcHeaderTb{
     height: 1.0417vw;
    }

    > p {
     @if $type == ""{
     }
     @if $type == sp{
      padding: 15px 40px 14px 20px;
      font-size: 14px;
      background: #242e44;
      transition: .2s background;
      position: relative;

      &:not(.notList) {
        > * {
          @if $type == sp{
            pointer-events: none;
          }
         }
      }


      &:not(.notList) {
        &:before,
        &:after {
         content: "";
         display: block;
         width: 13px;
         height: 2px;
         background: #fff;
         position: absolute;
         right: 24px;
         top: 0;
         bottom: 0;
         margin: auto;
        }
  
        &:after {
         transform: rotate(90deg);
         transition: transform .2s;
        }
  
        &.open {
         background: $c_base;
  
         &:after {
          transform: rotate(0deg);
         }
        }
      }
      
     }
     @if $type == pcHeaderTb{
      height: 3.9583vw;
      cursor: pointer;
     }
     @if $type == pcHeader{
     // height: 113px;
     }
     @if $type == pc{
      z-index: 1;
     }

     > a {
      @if $type == ""{
        display: block;
      }
      @if $type == sp{
       margin: -15px -40px -14px -20px;
       padding: 15px 50px 14px 20px;

       &[target="_blank"] {
          &:before {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            background: url(../images/common/icon_blank_white.svg) no-repeat center;
            background-size: 17px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 22px;
            transform: translateY(-1px);
          }
       }
       &:not([target="_blank"]) {
        &:before {
          content: "";
          display: block;
          width: 7px;
          height: 12px;
          background: url(../images/common/icon_arrow_white.svg) no-repeat;
          background-size: cover;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 28px;
          margin: auto;
         }
       }

       
      }
      @if $type == pc{
        &:after {
          display: none;
        }

        &[target="_blank"] {
          position: relative;

          &:before {
            content: "";
            display: inline-block;
            width: unquote("min(17px, 1.32vw)");
            height: unquote("min(17px, 1.32vw)");
            background: url(../images/common/icon_blank_black.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            left: inherit;
            margin: auto;
            right: -4px;
          }
       }
      }
     }

     > * {
      @if $type == ""{
      }
      @if $type == sp{
       font-size: 14px;
       color: #fff;
       
      }
      @if $type == pc{
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 15px;
       text-align: center;
       position: relative;
       transition: .2s color;
       position: relative;
       cursor: pointer;

       &:hover {
        color: $c_base;
       }

       &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6.5px 0 6.5px;
        transform: translateX(10px);
        border-color: #e2e2e2 transparent transparent transparent;
       }

       strong {
        display: flex;
        align-items: center;
        position: relative;

        &:before {
         content: "";
         display: block;
         height: 3px;
         background: $c_base;
         position: absolute;
         bottom: 0;
         right: 0;
         left: 0;
         margin: auto;
         width: 0;
         transition: .2s width;
        }
       }
      }
      @if $type == pcHeaderTb{
       font-size: 1.0417vw;
       font-size: clamp(1px, 1.23vw, 15px);
       height: 3.9583vw;
       padding: 0 1.4056vw;
       line-height: 1.2;

       &:after {
        border-width: 0.4167vw 0.4514vw 0 0.4514vw;
        transform: translateX(0.6944vw);
       }

       strong {
        height: 3.9583vw;
       }
      }
      @if $type == pcHeader{
      // height: 113px;
       padding: 0 24px;
       line-height: 1.4;

       strong {
        //height: 113px;
       }
      }
     }
    }

    > a {
     @if $type == ""{
     }
     @if $type == sp{
      display: block;
      padding: 15px 40px 14px 20px;
      font-size: 14px;
      color: #fff;
      background: #242e44;
      transition: .2s background;
      position: relative;

      &:before {
       content: "";
       display: block;
       width: 8px;
       height: 13px;
       background: url(../images/common/icon_arrow_white.svg) no-repeat;
       background-size: contain;
       position: absolute;
       top: 0;
       bottom: 0;
       right: 26px;
       margin: auto;
      }
     }
     @if $type == pc{
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 0 27px;
      text-align: center;
      line-height: 1.4;
      transition: .2s color;
      position: relative;

      &:hover {
       color: $c_base;
      }

      strong {
       display: flex;
       align-items: center;
       //height: 113px;
       position: relative;

       &:before {
        content: "";
        display: block;
        height: 3px;
        background: $c_base;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 0;
        transition: .2s width;
       }
      }
     }
     @if $type == pcHeaderTb{
      height: 3.9583vw;
      font-size: 1.0417vw;
      font-size: clamp(1px, 1.23vw, 15px);
      padding: 0 1.8056vw;

      strong {
       height: 3.9583vw;
      }
     }
     @if $type == pcHeader{
     // height: 113px;
     }
    }
   }

   .ghNavListItem {
    @if $type == sp{
     display: none;
    }
    @if $type == pc{
     display: none;
     width: 100%;
     padding-top: 50px;
     padding-bottom: 50px;
     position: absolute;
     background: #242e44;
    }
    @if $type == pcHeader{
     top: 132px;
     left: 0;
     right: 0;
     margin: auto;
    }
    @if $type == pcHeaderTb{
     top: 8.4722vw;
     left: 0;
    }

    > .inner {
     @if $type == pc{
      //display: flex;
      max-width: calc(1080px + 12%);
      padding: 0 6%;
      margin: 0 auto;
     }
     
     .ghNavListItemHeader {
      @if $type == sp{
       .ghNavListItemTitle {
        a {
         display: block;
         padding: 11px 40px 12px 35px;
         color: #fff;
         font-size: 13px;
         position: relative;
         
         &:before {
          content: "";
          display: block;
          width: 8px;
          height: 13px;
          background: url(../images/common/icon_arrow_white.svg) no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 26px;
          margin: auto;
         }
        }
       }
       
       .ghCloseBtn {
        display: none !important;
       }
      }
      @if $type == pc{
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding-bottom: 6px;
       margin-bottom: 23px;
       border-bottom: 1px solid #fff;
       
       .ghNavListItemTitle {
        font-size: 30px;
        
        a {
         display: inline-block;
         color: #fff;
         transition: .2s color;
         
         &:hover {
          color: $c_base;
         }
         
        }

       }
       
       .ghCloseBtn {
        flex: none;
        padding: 11px 19px 11px 40px;
        font-size: 14px;
        color: #242e44;
        line-height: 1;
        background: #fff;
        position: relative;
        cursor: pointer;
        transition: .2s color;
        
        &:hover {
         color: $c_base;
         
         &:before,
         &:after {
          background: $c_base;
         }
        }
        
        &:before,
        &:after {
         content: "";
         display: block;
         width: 17px;
         height: 1px;
         background: #242e44;
         position: absolute;
         top: 18px;
         left: 18px;
         margin: auto;
         transition: .2s background;
        }
        
        &:before {
         transform: rotate(45deg);
        }
        &:after {
         transform: rotate(-45deg);
        }
       }
      }
     }

     .ghNavThumb {
      @if $type == pc{
       width: 21.7593%;
       overflow: hidden;
      }

      a {
       @if $type == pc{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        font-size: 15px;
        transition: .2s background;
        position: relative;

        &:before {
         content: "";
         display: block;
         width: 100%;
         height: 100%;
         background: $c_navy;
         opacity: .65;
         position: absolute;
         top: 0;
         margin: auto;
         left: -100%;
         transition: .2s left;
        }

        &:hover {
         &:before {
          left: 0;
         }
        }

        p {
         position: relative;
        }
       }
      }
     }

     .ghNavLinkArea {
      @if $type == pc{
       width: 78.33%;
       padding: 2.6596vw 6.974% 1.3830vw 7.0922%;

       > p {
        text-align: right;
        margin-top: 1.0638vw;

        > a {
         font-size: 14px;
         color: #fff;
         transition: .2s opacity;

         &:hover {
          opacity: .7;
         }
        }
       }
      }
      @if $type == pcHeader{
       padding: 50px 6.974% 26px 7.0922%;

       > p {
        margin-top: 20px;
       }
      }

      .ghNavLinkList {
       @if $type == pc{
        display: flex;
        flex-wrap: wrap;

        > li {
         width: calc(33.3% - 2px);
         margin-left: 2px;
         margin-top: 2px;
         overflow: hidden;

         > a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          min-height: 50px;
          padding-right: 8px;
          padding-left: 8px;
          text-align: center;
          color: $c_navy;
          font-size: 14px;
          line-height: 1.4;
          background: #fff;
          transition: .2s color;
          position: relative;

          &:hover {
           color: #fff;

           &:before {
            left: 0;
           }
          }

          &:before {
           content: "";
           display: block;
           width: 100%;
           height: 100%;
           background: $c_base;
           position: absolute;
           top: 0;
           margin: auto;
           left: -100%;
           transition: .2s left;
          }

          > p {
           position: relative;
          }
         }
        }
       }
      }
     }

     .ghPickupArea {
      @if $type == pc{
       width: 100%;
       font-size: 15px;
      }
      @if $type == pcHeader{
       font-size: 15px;
      }

      .ghPickupList {
       @if $type == pc{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        
        &.col3 {
         > li {
          width: calc(33% - 28px);

          &:not(:nth-child(4n)) {
           margin-right: 0;
          }
          
          &:not(:nth-child(3n)) {
           margin-right: 45px;
          }
         }
        }
       }

       > li {
        @if $type == pc{
         width: calc(25% - 34px);
         
         &:not(:nth-child(4n)) {
          margin-right: 45px;
         }
        }
        
        > p {
         @if $type == sp{
          &:not(.pickupTitle) {
           display: none !important;
          }
         }
         @if $type == pc{
          color: #fff;
          font-size: 18px;
          font-weight: bold;
         }
        }
        

        a {
         @if $type == pc{
          color: #fff;
          line-height: 1.4;

          &:hover {
           .pickupAreaThumb {
            img {
             transform: scale(1.1);
            }
           }
           
           p {
            color: $c_base;
           }
          }
         }
        }
        
        > * + ul {
         @if $type == "" {
         }
         @if $type == sp {
         }
         @if $type == pc {
          margin-top: 20px;
         }
        }
        
        > ul {

         > li {
          @if $type == "" {
          }
          @if $type == sp {
          }
          @if $type == pc {
           + li {
            margin-top: 12px;
            line-height: 1.466;
           }
          }
          
          > a {
           @if $type == "" {
           }
           @if $type == sp {
           }
           @if $type == pc {
            display: inline-block;
            padding-left: 17px;
            font-size: 15px;
            position: relative;
            transition: .2s color;
            
            &:hover {
             color: $c_base;
            }
            
            &:before {
             content: "";
             display: block;
             width: 9px;
             height: 12px;
             background: url(../images/common/icon_dash_white.svg) no-repeat center;
             background-size: contain;
             position: absolute;
             top: 4px;
             left: 0;
            }
           }
          }
         }
        }

        .pickupAreaThumb {
         @if $type == sp{
          display: none;
         }
         @if $type == pc{
          display: block;
          margin-bottom: 8px;
          position: relative;
          font-weight: 400;
          overflow: hidden;

          
          img {
           display: block;
           width: 100%;
           object-fit: cover;
           transition: .2s transform;
          }

          
          + p {
           font-size: 18px;
           font-weight: bold;
           transition: .2s color;
           
           a {
            transition: .2s color;
            &:hover {
             color: $c_base;
            }
           }
          }
         }
         @if $type == pcHeader{
          margin-bottom: 11px;

          &:before {
           font-size: 15px;
          }
         }
        }
       }
      }
     }

     .ghNavLinkArea {
      @if $type == sp{
       > p {
        display: none;
       }
      }
     }
     
     .pickupTitle{
      @if $type == sp{
       border-top: 1px solid #35415f;
       a {
        display: block;
        padding: 11px 40px 12px 35px;
        color: #fff;
        font-size: 13px;
        position: relative;

        &:before {
         content: "";
         display: block;
         width: 8px;
         height: 13px;
         background: url(../images/common/icon_arrow_white.svg) no-repeat;
         background-size: contain;
         position: absolute;
         top: 0;
         bottom: 0;
         right: 26px;
         margin: auto;
        }
       }
      }
      
     }

     .ghPickupList,
     .ghPickupList > li > ul,
     .ghNavLinkList {
      @if $type == sp{
       > li {

        & + li {
         border-top: 1px solid #35415f;
        }

        > a {
         display: block;
         padding: 11px 40px 12px 35px;
         color: #fff;
         font-size: 13px;
         position: relative;

         &:before {
          content: "";
          display: block;
          width: 8px;
          height: 13px;
          background: url(../images/common/icon_arrow_white.svg) no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 26px;
          margin: auto;
         }
        }
       }
      }
     }

     .ghNavThumb {
      @if $type == sp{
       > a {
        display: block;
        padding: 11px 40px 12px 35px;
        color: #fff;
        font-size: 13px;
        position: relative;

        &:before {
         content: "";
         display: block;
         width: 8px;
         height: 13px;
         background: url(../images/common/icon_arrow_white.svg) no-repeat;
         background-size: contain;
         position: absolute;
         top: 0;
         bottom: 0;
         right: 26px;
         margin: auto;
        }
       }
      }
     }

     .ghOtherList {
      @if $type == sp{
       border-top: 1px solid #35415f;
      }
     }

    }
   }
  }

  .ghList {
   @if $type == ""{
   }
   @if $type == sp{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 30px;
   }
   @if $type == pc{
    width: 3.0435vw;
    height: 13.041vw;
    //height: 1.30435vw;
    position: fixed;
    right: 0;
    margin: auto;
    z-index: 100;
   }
   @if $type == pcHeader{
    width: 56px;
    height: 240px;
    top: calc(37.28vw - 27.982vw);
    transform: translateY(113px);
    //top: calc(37.28vw - 113px - 6.5205vw);
   }
   @if $type == pcHeaderTb{
    width: 56px;
    height: 240px;
    top: 0;
    margin-top: calc(13vw + -90px);
    transform: translateY(8.4722vw);
    //top: calc(37.28vw - 122px - 6.5205vw);
   }

   > li {
    + li {
     @if $type == pc{
      margin-top: 5px;
     }
    }
    
    &.ghListContact {
     @if $type == sp{
      width: 100%;
      margin-bottom: 7px;
     }
     a {
      @if $type == ""{
       background: #c90056;
      }
      @if $type == pc{
       &:hover {
        background: #ab002e;
       }
      }
     }
    }

    &.ghListDocument {
     @if $type == sp{
      width: calc(50% - 4px);
     }
     a {
      @if $type == ""{
       background: #4c4398;
      }
      @if $type == pc{
       &:hover {
        background: #3c3486;
       }
      }
     }
    }
    &.ghListRecruit {
     @if $type == sp{
      width: calc(50% - 4px);
     }
     a {
      @if $type == ""{
       background: #0062ac;
      }
      @if $type == pc{
       &:hover {
        background: #00509b;
       }
      }
     }
    }

    > a {
     @if $type == ""{
     }
     @if $type == sp{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 13px 10px 16px;
      color: #fff;
      font-size: 14px;
     }
     @if $type == pc{
      display: flex;
      align-items: center;
      justify-content: center;
      //height: 4.347vw;
      padding: 20px 0;
      line-height: 1.173;
      transition: .2s background;
     }
     @if $type == pcHeader{
     }
     @if $type == pcHeaderTb{
     }

     .iconContact {
      @if $type == ""{
       background: url(../images/common/icon_mail.svg) no-repeat center;
       background-size: contain;
      }
      @if $type == sp{
       width: 18px;
       height: 12px;
       margin-right: 9px;
      }
      @if $type == pc{
       display: none;
       //width: 18.870vw;
       //height: 0.7065vw;
       //width: 20px;
       //height: 13px;
      }
     }

     .iconDocument {
      @if $type == ""{
       background: url(../images/common/icon_document.svg) no-repeat center;
       background-size: contain;
      }
      @if $type == sp{
       width: 14px;
       height: 18px;
       margin-right: 10px;
      }
      @if $type == pc{
       display: none;
       //width: 0.9239vw;
       //height: 1.1413vw;
       //width: 17px;
       //height: 21px;
      }
     }

     .iconCad {
      @if $type == ""{
       
      }
      @if $type == sp{
       width: 18px;
       height: 18px;
       margin-right: 7px;
       display: inline-block;
       background: url(../images/common/icon_cad.svg) no-repeat center;
      background-size: contain;

      }
      @if $type == pc{
       display: none;
      }
     }

     > span {
      @if $type == ""{
      }
      @if $type == sp{
      }
      @if $type == pc{
       color: #fff;
       font-size: 15px;
       font-weight: bold;
       letter-spacing: 0.04em;
       writing-mode: vertical-rl;
       text-orientation: upright;
      }
     }

    }
   }
  }

  .ghLanguage {
   @if $type == ""{
    @include fontRo;
   }
   @if $type == sp{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 25px;
   }
   @if $type == pc{
    display: flex;
    align-items: center;
    margin-right: 3px;
   }
   @if $type == pcHeaderTb{
    position: absolute;
    top: 1.975vw;
    right: 3.8194vw;
   }
   @if $type == pcHeader{
    position: absolute;
    top: 42px;
    right: 80px;
   }

   > li {
    @if $type == ""{
    }
    @if $type == sp{
     font-weight: 500;
     position: relative;
    }
    @if $type == pc{
     display: flex;
     align-items: center;
    }
    
    
    &:after {
     @if $type == ""{
      content: "";
      width: 1px;
      background: #cecece;
     }
     @if $type == sp{
     // display: block;
     // height: 13px;
     // position: absolute;
     // top: 0;
     // bottom: 0;
     // left: -0.5px;
     // margin: auto;
     }
     @if $type == pc{
      display: inline-block;
      height: 15px;
     }
     @if $type == pcHeaderTb{
      height: 1.0417vw;
     }
    }

    a {
     @if $type == ""{
     }
     @if $type == sp{
      padding: 0 15px;
      color: #fff;
      letter-spacing: 0.06em;
     }
     @if $type == pc{
      display: inline-block;
      font-weight: 500;
      padding: 0 14px;
      font-size: 15px;
      transition: .2s color;
      letter-spacing: .08em;

      &:hover {
       color: $c_base;
      }
     }
     @if $type == pcHeaderTb{
      padding: 0 0.9722vw;
      font-size: clamp(1px, 1.23vw, 15px);
     }
    }

   }
  }

  .ghSearchWrap {
   @if $type == ""{
   }
   @if $type == sp{
   }
   @if $type == pc{
    display: flex;
    height: 113px;
    margin-left: 6px;
    position: relative;
   }
   @if $type == pcHeaderTb{
    margin-left: 0;
    height: inherit;
    position: absolute;
    top: 2.2222vw;
    right: 1.6667vw;
   }

   .ghSearchBtn {
    @if $type == ""{
     display: block;
     background: url(../images/common/icon_search_black.svg) no-repeat;
     background-size: contain;
    }
    @if $type == sp{
     border-image-source: none !important;
    }
    @if $type == pc{
     width: 20px;
     height: 21px;
     margin: auto;
     cursor: pointer;
     transition: opacity;

     &:hover {
      opacity: .7;
     }
    }
    @if $type == pcHeaderTb{
     width: clamp(10px, 1.3889vw, 20px);
     height: clamp(10px, 1.4583vw, 21px);
    }
   }

   .ghSearch {
    @if $type == ""{
    }
    @if $type == sp{
     display: block !important;
    }
    @if $type == pc{
     display: none;
     width: 400px;
     padding: 16px 24px;
     height: 72px;
     background: $c_navy;
     position: absolute;
    }
    @if $type == pcHeader{
     position: absolute;
     right: 0;
     bottom: -92px;
    }
    @if $type == pcHeaderTb{
     position: fixed;
     top: 8.4722vw;
     right: 6.8611vw;
    }

    #___gcse_0 {
     @if $type == ""{
      background: #fff;
     }
    }

    form.gsc-search-box {
     @if $type == ""{
      margin-bottom: 0 !important;
     }
    }

    table.gsc-search-box {
     @if $type == ""{
      margin-bottom: 0 !important;
     }
    }

    .gsib_a {
     @if $type == ""{
      padding: 0 !important;
     }
    }

    table.gsc-search-box td.gsc-input {
     @if $type == ""{
      padding: 0 !important;
     }
    }

    .gsc-input-box {
     @if $type == ""{
      border: none;
     }
     @if $type == sp{
     }
     @if $type == pc{
     }
    }

    .gsc-input {
     @if $type == ""{
     }
     @if $type == sp{
      height: 36px;
      background-position: left 10px center !important;
     }
     @if $type == pc{
      height: 38px;
      background-position: left 10px center !important;
     }
    }

    .gsst_a {
     @if $type == ""{
      display: flex;
      align-items: center;
      justify-content: center;
     }
    }

    .gscb_a {
     @if $type == ""{
      color: $c_navy !important;
     }
    }

    .gsc-input {
     @if $type == ""{

     }
     @if $type == sp{
      padding-left: 10px !important;
     }
     @if $type == pc{
      padding-left: 10px !important;
      font-size: 1.23vw;
      font-size: clamp(1px, 1.23vw, 15px);
     }
     @if $type == pcHeader{
      font-size: 15px;
     }
    }

    .gsc-search-button-v2 {
     @if $type == ""{
      -webkit-appearance: none;
      appearance: none;
      border: none;
      background: url(../images/common/icon_search_black.svg) no-repeat center;
      background-size: 20px 21px;
     }
     @if $type == sp{
      width: 45px;
      height: 36px;
      padding: 0 10px;
      cursor: pointer;
     }
     @if $type == pc{
      width: 40px;
      height: 38px;
      padding: 9px 10px 8px;
      cursor: pointer;
     }

     svg {
      @if $type == ""{
       display: none;
      }
     }
    }
   }
  }
 }
}

// footer
#footContact {
 @if $type == ""{
 }

 @if $type == sp{
  padding: 53px 35px 59px;
 }

 @if $type == pc{
  padding-top: 88px;
  padding-bottom: 82px;
 }

 .inner {
  @if $type == ""{
  }

  @if $type == sp{
   @include maxSP;
  }

  @if $type == pc{
   @include maxPC;
  }

  .footContactNotes {
   @if $type == ""{
    text-align: center;
   }
   @if $type == sp{
    margin-bottom: 35px;
   }
   @if $type == pc{
    margin-bottom: 40px;
    font-size: 18px;
   }
  }
 }
}

.sectionHeader {
 @if $type == ""{
 }
 @if $type == sp{
  margin-bottom: 21px;
 }
 @if $type == pc{
  margin-bottom: 26px;
 }

 .sectionTitle {
  @if $type == ""{
   text-align: center;
   font-weight: 500;
  }
  @if $type == sp{
   margin-bottom: 8px;
   font-size: 24px;
  }
  @if $type == pc{
   margin-bottom: 2px;
   font-size: 30px;
  }
 }

 .sectionTitleSub {
  @if $type == ""{
   margin-top: 0;
   text-align: center;
   color: $c_base;
   line-height: 1;
   font-weight: bold;
   @include fontRo;
   letter-spacing: .06em;
  }
  @if $type == sp{
   font-size: 12px;
  }
  @if $type == pc{
   font-size: 14px;
  }
 }
}

// column
.pickupListItem {
 @if $type == ""{
 }
 @if $type == sp{
  padding-bottom: 25px;
  margin-bottom: 0;
 }
 @if $type == pc{
  display: flex;
  width: 74.4167%;
 }

 .pickupItem {
  @if $type == ""{
   background: #fff;
  }
  @if $type == sp{
   width: calc(100vw - 140px);
   margin: 0 15px;
  }
  @if $type == pc{
    display: flex;
    flex-direction: column;
   width: 31.5789%;
   box-shadow: 0 3px 10px rgba(0, 0, 0, .23%);

   &:not(:first-child) {
    margin-left: 2.57%;
   }
  }

  > a {
   @if $type == ""{
    overflow: hidden;
    position: relative;
   }
   @if $type == sp{
    display: block;
    padding-top: 44.13vw;
   }
   @if $type == pc{
    display: flex;
    height: 100%;
    padding-top: 150px;
    z-index: 1;

    &:hover {
     .pickupItemThumb {
      transform: scale(1.1);
     }
    }
   }

   .pickupItemThumb {
    @if $type == ""{
    }
    @if $type == sp{
     height: 51.33vw;
     position: absolute;
     top: 0;
     right: 0;
     left: 0;
     margin: auto;

     img {
      object-fit: cover;
      width: 100%;
      height: auto;
     }
    }
    @if $type == pc{
     position: absolute;
     top: 0;
     bottom: 0;
     right: 0;
     left: 0;
     margin: auto;
     transition: .3s transform;
     z-index: 0;

     img {
      width: 100%;
      height: auto;
      object-fit: cover;
      z-index: 0;
     }
    }
   }

   .pickupItemText {
    @if $type == ""{
    }
    @if $type == sp{
     position: relative;
    }
    @if $type == pc{
      width: 100%;
     position: relative;
     z-index: 1;
    }

    .pickupItemTag {
     @if $type == ""{
      display: inline-block;
      color: $c_base;
      line-height: 1;
      background: #fff;
      position: relative;
     }
     @if $type == sp{
      min-height: 24px;
      padding: 10px 30px 0 25px;
      font-size: 14px;
						z-index: 10;
     }
     @if $type == pc{
      min-height: 24px;
      padding: 10px 25px 0 20px;
      font-size: 14px;
      z-index: 10;
     }

     &:before {
      @if $type == ""{
       content: "";
       height: 1px;
       background: #dadada;
       position: absolute;
       left: 0;
      }
      @if $type == sp{
       width: 21px;
       bottom: 6px;
      }
      @if $type == pc{
       width: 16px;
       bottom: 6px;
      }
     }
    }

    .pickupItemNote {
     @if $type == ""{
      background: #fff;
     }
     @if $type == sp{
      min-height: 73px;
      padding: 14px 25px 17px;
      margin-top: -7px;
      font-size: 14px;
      line-height: 1.6196;
     }
     @if $type == pc{
      height: calc(100% - 24px);
      padding: 14px 24px 17px;
      margin-top: -7px;
      font-size: 14px;
      line-height: 1.5;
     }
    }
   }
  }
 }

 .slick-dots {
  @if $type == sp{
   bottom: 0;

   > li {
    width: 8px;
    height: 8px;
    margin: 0 5px;

    button {
     width: 8px;
     height: 8px;
     padding: 0;
     border-radius: 50%;
     background: $c_navy;
    }

    &.slick-active {
     button {
      background: $c_base;
     }
    }
   }
  }
 }
}

#globalFooter {
 @if $type == ""{
  position: relative;
 }
 @if $type == sp{
  display: flex;
  flex-direction: column;
 }
 @if $type == pc{
  width: 100%;
  background: #ebf0f4;
 }

 .gfSitemap {
  @if $type == ""{
  }
  @if $type == sp{
   display: flex;
   flex-direction: column;
  }
  @if $type == pc{
			width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
			padding: 70px 0 55px;
  }
  @if $type == tb{
  }
		@media all and (-ms-high-contrast: none) and (min-width: 981px) {
			justify-content: flex-start !important;
		}
		@media all and (-ms-high-contrast: none) and (min-width: 1640px) {
			justify-content: flex-start !important;
		}

  .gfAddress {
   @if $type == ""{
   }
   @if $type == sp{
    order: 1;
    padding: 20px 15px;
   }

   @if $type == pc{
    order: 1;
    width: 100%;
    margin: 73px auto 0;
   }
   @if $type == tb{
    width: 100%;
    max-width: inherit;
   }

   .gfLogoList {
    @if $type == ""{
    }
    @if $type == sp{
    }
    @if $type == pc{
     display: flex;
     align-items: center;
     justify-content: center;
    }
    @if $type == tb{
    }
				@media all and (-ms-high-contrast: none) and (min-width: 981px) {
					justify-content: flex-start !important;
				}

    > li {
     @if $type == ""{
     }
     @if $type == sp{
      + li {
       margin-top: 18px;
      }
      
      &:nth-child(1) {
       a {
        max-width: 222px;
       }
      }
      &:nth-child(2) {
       a {
        max-width: 162px;
       }
      }
     }
     @if $type == pc{
      margin: 0 50px;

      &:nth-child(1) {
       img {
        max-width: 222px;
       }
      }
      &:nth-child(2) {
       img {
        max-width: 162px;
       }
      }
     }

     > a {
      @if $type == ""{	
       display: block;
      }
      @if $type == sp{
       margin: 0 auto;
      }
      @if $type == pc{
      }

      img {
       @if $type == ""{	
       }
       @if $type == sp{
       }
       @if $type == pc{
        display: block;
        margin: 0 auto;
       }
      }
     }
    }
   }

   .gfAddressNote {
    @if $type == ""{
    }
    @if $type == sp{
     max-width: 300px;
     margin: 16px auto 0;
     font-size: 14px;
     line-height: 1.5;
    }
    @if $type == pc{
     font-size: 15px;
     line-height: 1.6;
    }


    a {
     @if $type == ""{
     }
     @if $type == sp{
     }
					@if $type == pc{
     }
    }
   }
  }

  .gfSitemapList {
   @if $type == sp{
    order: 0;
				border-top: 1px solid #e2e2e2;
				background: #fff;
				
				> li:not(.isActive) {
					display: none;
				}
				
				a:before {
					content: "";
					display: block;
					width: 7px;
					height: 12px;
					background: url(../images/common/icon_arrow_black.svg) no-repeat;
					background-size: cover;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 20px;
					margin: auto;
				}
				
				.gfSitemapListTitle {
					font-weight: bold;
					font-size: 14px;
					
					> a {
						display: block;
						padding: 13px 35px 13px 20px;
						position: relative;
					}
				}
				
				.gfSitemapListItem {
					border-top: 1px solid #e2e2e2;
					
					> li {
						> a {
							display: block;
							padding: 13px 35px 13px 35px;
							font-size: 13px;
							border-bottom: 1px solid #e2e2e2;
							position: relative;
						}
					}
				}
   }
   @if $type == pc{
    display: flex;
    width: 100%;
    max-width: 1360px;
    padding-right: 80px;
    padding-left: 80px;
    margin: 0 auto;

    > li {
     margin: 0 auto;
     padding:  0 10px;

     a {
      transition: .2s opacity;

      &:hover {
       opacity: .7;
      }
     }

     .gfSitemapListTitle {
      font-size: 15px;
      font-weight: bold;

      + .gfSitemapListTitle {
       margin-top: 24px;
      }
     }

     .gfSitemapListItem {
      margin-top: 24px;
      font-size: 12px;

      li + li {
       margin-top: 8px;
      }
     }
    }
   }
   @if $type == tb{
    justify-content: center;
    width: 100%;
    max-width: inherit;
    margin-top: 60px;
   }
			@media all and (-ms-high-contrast: none) and (min-width: 981px) {
				justify-content: flex-start !important;
			}
  }
 }
 .gfSub {
  @if $type == ""{
   width: 100%;
  }
  @if $type == sp{
			order: 2;
   background: #ebf0f4;
  }
  @if $type == pc{
   display: flex;
   align-items: center;
   justify-content: space-between;
   max-width: 1440px;
   margin: 0 auto;
   padding: 0 8px;
   min-height: 66px;
   border-top: 1px solid #283148;
  }
		@media all and (-ms-high-contrast: none) and (min-width: 981px) {
			height: 66px !important;
		}

  .gfNav {
   @if $type == ""{
   }
   @if $type == sp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% - 70px);
    padding: 22px 0;
    margin: 0 auto;
   }
   @if $type == pc{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    order: 1;
   }

   > li {
    @if $type == ""{
     position: relative;
    }
    @if $type == sp{
     margin: 7px 0;

     &:before,
     &:after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      background: #c1c1c1;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
     }

     &:before {
      left: -1px;
     }

     &:after {
      right: 0;
     }
    }
    @if $type == pc{
     display: flex;
     align-items: center;
    }

    + li {
     @if $type == pc{
      &:before {
       content: "";
       display: block;
       width: 1px;
       height: 14px;
       background: #333333;
       position: absolute;
       left: 0;
       top: 0;
       bottom: 0;
       margin: auto;
      }
     }
    }

    > a {
     @if $type == ""{
     }
     @if $type == sp{
      padding: 0 10px;
      font-size: 12px;
     }
     @if $type == pc{
      padding: 0 10px;
      font-size: 12px;
      transition: .2s opacity;
      vertical-align: middle;

      &:hover {
       opacity: .5;
      }
     }
    }
   }
  }

  small {
   @if $type == ""{
   }
   @if $type == sp{
    display: block;
    text-align: center;
    min-height: 49px;
    padding: 15px 55px 16px;
    border-top: 1px solid #283148;
    font-size: 11px;
   }
   @if $type == pc{
    font-size: 11px;
    flex: none;
   }
  }
 }

 #pageTop {
  @if $type == ""{
   background: $c_navy;
   position: absolute;
  }
  @if $type == sp{
   width: 50px;
   height: 49px;
   background-size: contain;
   right: 0;
   bottom: 0;
   z-index: 2;
  }
  @if $type == pc{
   width: 67px;
   height: 65px;
   cursor: pointer;
   transition: transform .2s;
   background-size: contain;
   right: 80px;
   top: -35px;

   &:hover {
    transform: translateY(-15px);
   }
  }
  
  &:before {
   @if $type == ""{
    content: "";
     display: block;
    background: url(../images/common/icon_dash_white.svg) no-repeat center;
    background-size: contain;
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
   }
   @if $type == sp{
    width: 18px;
    height: 14px;
   }
   @if $type == pc{
    width: 18px;
    height: 14px;
   }

  }
 }
}

.cookieConsentLayout {
	@if $type =="" {
		background: rgba(255, 255, 255, .7);
		position: fixed;
		z-index: 99;
		transition: .4s 1s transform ease-in-out;
		// transition: .4s ease-in-out;
		transform: translateY(0);
		backdrop-filter: blur(8px);
		z-index: 102;

		&:not(.jsCookieConsent) {
			transform: translateY(calc(100% + 50px));
		}
	}

	@if $type ==sp {
		width: 100%;
		padding: 50px 0 20px;
		font-size: 13px;
		font-weight: 600;
		left: 0;
		bottom: 0;
	}

	@if $type ==pc {
		width: 100%;
		padding: 20px 0;
		left: 0;
		bottom: 0;
	}

	.cookieInner {
		@if $type =="" {}

		@if $type ==sp {
			padding: 0 20px;
		}

		@if $type ==pc {
			display: flex;
			align-items: center;
			width: 100%;
			max-width: 1400px;
			padding-right: 100px;
			padding-left: 100px;
			margin-inline: auto;
		}

		@if $type ==tb {}
	}

	.cookieText {
		@if $type =="" {
			font-weight: bold;
		}

		@if $type ==sp {}

		@if $type ==pc {
			width: calc(100% - 203px);
			font-size: 14px;
			line-height: 1.6;
			letter-spacing: 0.05em;
		}

		@if $type ==tb {
			width: 100%;
		}

		a {
			@if $type =="" {
				color: $c_navy;
				text-decoration: underline;
			}

			@if $type ==sp {}

			@if $type ==pc {

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}

	.cookieBtn {
		@if $type =="" {
			appearance: none;
			border: none;
			text-align: center;
			background: transparent;
		}

		@if $type ==sp {
			margin-top: 15px;
		}

		@if $type ==pc {
			flex: none;
			margin-top: 0;
			margin-left: 30px;
		}

		@if $type ==tb {
			margin-left: 20px;

		}

		>a {
			@if $type =="" {
				text-align: center;
				font-weight: bold;
				text-decoration: none;
				letter-spacing: 0;
				color: #fff;
				border-radius: 9999px;
				background: $c_navy;
				position: relative;
			}

			@if $type ==sp {
				display: inline-block;
				padding: 6px 18px;
				font-size: 14px;
				margin: 0 auto;

				+.btn {
					margin-top: 18px;
				}
			}

			@if $type ==pc {
				display: block;
				padding: 6px 18px;
				font-size: 15px;
				transition: 0.4s background, 0.4s color;
				cursor: pointer;

				&:focus,
				&:hover {
					color: #fff;
					background: #13192a;
				}

				>span {
					position: relative;
					transition: 0.4s opacity;
				}
			}

			@if $type ==tb {
				min-width: 40%;
			}

		}
	}

	.cookieClose {
		@if $type =="" {
			appearance: none;
			border: none;
			background: transparent;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 25px;
			height: 25px;
			transition: all 0.4s;
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
			position: absolute;
			z-index: 10;
			cursor: pointer;
		}

		@if $type ==sp {
			right: 20px;
			top: 15px;
		}

		@if $type ==pc {
			right: 40px;
			top: 0;
			bottom: 0;
			margin: auto;

			&:focus,
			&:hover {
				span {
					opacity: .7;
				}
			}
		}

		>span {
			@if $type =="" {
				display: block;
				width: 25px;
				height: 2px;
				background: $c_navy;
				position: absolute;
				right: 0;
				left: 0;

				&:nth-child(1) {
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					transform: rotate(-45deg);
				}
			}

			@if $type ==sp {}

			@if $type ==pc {
				transition: .4s opacity;
			}
		}
	}
}

/*
:target {
 @if $type == sp{
  animation: anchor_sp  0.1s;
 }
	@if $type == pcHeaderTb{
  animation: anchor_tb  0.1s;
 }
	@if $type == pcHeader{
  background: #000;
  animation: anchor_pc  0.1s;
 }
}

@keyframes  anchor_sp{
 0% { padding-top: 120px; margin-top: -120px; }
 100% { padding-top: 0; margin-top: 0; }
}

@keyframes  anchor_tb{
 0% { padding-top: 8.4722vw; margin-top: -8.4722vw; }
 100% { padding-top: 0; margin-top: 0; }
}

@keyframes  anchor_pc{
 0% { padding-top: 226px; margin-top: -226px; }
 100% { padding-top: 0; margin-top: 0; }
}
*/


/* ココから下には記入禁止 */

.forPC{	@if $type == sp{display: none!important;}}
.forSP{	@if $type == pc{display: none!important;}}
