/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:980px;
$breakpoint_tb		:1280px;
$breakpoint_pc		:$breakpoint_sp + 1;


/* Color */
$c_base : #c90056;/*サイトカラー*/
$c_text : #000; /*テキストに使うフォントカラー*/
$c_navy : #34405b;


@mixin maxSP {
 max-width: 700px;
 margin: 0 auto;
}

@mixin maxPC {
 max-width: 1280px;
 padding: 0 40px;
 margin: 0 auto;
}

@mixin fontHi {
 font-family: "Hiragino Kaku Gothic ProN", 'Noto Sans JP', sans-serif;
}

@mixin fontPo {
 font-family: 'Poppins', 'Noto Sans JP', sans-serif;
}

@mixin fontRo {
 font-family: 'Roboto', 'Noto Sans JP', sans-serif;
}